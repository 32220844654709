import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-shipment-timeline',
  templateUrl: './shipment-timeline.component.html',
  styleUrls: ['./shipment-timeline.component.css']
})
export class ShipmentTimelineComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
