import { Component, OnInit, ViewChild } from "@angular/core";
import {
  faHome,
  faCaretRight,
  faSort,
  faCheck,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { HttpClient } from "@angular/common/http";
import { HttpProvider } from "../../common/http/http.provider";
import { HttpHeaders } from "@angular/common/http";
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { SidenavComponent } from "../sidenav/sidenav.component";
import { Router } from "@angular/router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  MdbTableDirective,
  MdbTablePaginationComponent,
} from "angular-bootstrap-md";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
@Component({
  selector: "app-backofficeuser-shippingtype",
  templateUrl: "./backofficeuser-shippingtype.component.html",
  styleUrls: ["./backofficeuser-shippingtype.component.css"],
})
export class BackofficeuserShippingtypeComponent implements OnInit {
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  cargoData: any = [];
  registerForm: FormGroup;
  notesModal: boolean = false;
  submitted = false;
  cargo: any = {
    id: "",
    notes: "",
  };
  request: any = "";
  page = 1;
  pageSize = 25;
  numberOfRecords: number;
  elements: any = [];
  isDesc: boolean = false;
  column: string = "CategoryName";
  headElements = ["shipper information", "consignee information", "Notes"];
  searchText: string = "";
  previous: string;
  faHome = faHome;
  faCaretRight = faCaretRight;
  faSort=faSort;
  faCheck = faCheck;
  faTimes = faTimes;
  constructor(
    private component: SidenavComponent,
    private _router: Router,
    private http: HttpProvider,
    private httpClient: HttpClient,
    private formBuilder: FormBuilder,
    private ngxService: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.getcargoData();
    this.registerForm = this.formBuilder.group({
      notes: ["", Validators.required],
    });
  }
  get f() {
    return this.registerForm.controls;
  }
  //getting cargo data's
  getcargoData() {
    this.httpClient
      .get(this.http.BASE_URI + "getNotifications")
      .subscribe((response: any) => {
        this.cargoData = [];
        this.cargoData=response.all
        this.loadPage(this.page);
        // console.log("cargodata" + this.cargoData);
      });
  }
   
  updateRequest(request,id) {
    
    var requestData = {
      status: request,
      is_viewed:'yes', 
      accepted_by: localStorage.getItem("Id"),
    };
    //  console.log('requestData'+request)
    //  console.log("cargo id"+id);return;
    // console.log("this.cargostatus"+this.customer.name);return;
    let header = new HttpHeaders();
    this.ngxService.start();
    header.set("Access-Control-Allow-Origin", "*");
    this.httpClient
      .post(this.http.BASE_URI + "acceptRequest/" + id, requestData)
      .subscribe((response: any) => {
        // console.log("Response", response);
        var status = response.success;
        if (status) {
          this.ngxService.stop();
          Swal.fire("", response.message, "success");
        }else{
          Swal.fire("", response.message, "warning");

        }
        this.getcargoData();
        this.cargo.id = "";
        this.closeNotesModal();
      });
  }
  //delete request
  deleteRequest(id){
    Swal.fire({
      title: "Are you sure want to delete this record?",
      //  text: 'You will not be able to recover this data!',
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.ngxService.start();
        this.httpClient
          .delete(this.http.BASE_URI + "shippingType/delete/" + id)
          .subscribe((response: any) => {
            // console.log("Response", response);
            this.getcargoData();
            this.ngxService.stop();
          });
        Swal.fire("Deleted!", "Your data has been deleted.", "success");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }
  //open notes modal
  openNotesModal(e, i) {
    this.notesModal = true;
    this.cargo.id = e.id;
    this.cargo.notes = e.status;
  }
  //close notes modal
  closeNotesModal() {
    this.notesModal = false;
  }
  homeLink() {
    this.component.ngOnInit();
    if (localStorage.getItem("Role") == "Admin") {
      this._router.navigateByUrl("/adminDashboard");
    } else {
      this._router.navigateByUrl("/backofficeDashboard");
    }
  }
  sort(property) {
    // console.log(property)
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;
    this.cargoData.sort(function (a, b) {
      if (a[property].toLowerCase() < b[property].toLowerCase()) {
        return -1 * direction;
      }
      else if (a[property].toLowerCase() > b[property].toLowerCase()) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  sort1(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;
    this.cargoData.sort(function (a, b) {
    if (parseInt(a[property]) < parseInt(b[property])) {
    return -1 * direction;
    }
    else if (parseInt(a[property]) > parseInt(b[property])) {
    return 1 * direction;
    }
    else {
    return 0;
    }
    });
    };
  //showing pagination count
  loadPage(e){
    console.log('length'+this.cargoData.length)
    this.numberOfRecords = (e - 1) * this.pageSize + this.pageSize > this.cargoData.length ? this.cargoData.length :  (e - 1) * this.pageSize + this.pageSize;
  }
}
