// import { Component, OnInit } from '@angular/core';
import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import {
  faSort,
  faPlus,
  faEye,
  faEdit,
  faTimes,
  faCheck,
  faCheckDouble,
  faCaretRight,
  faHome,
} from "@fortawesome/free-solid-svg-icons";
import {
  MdbTableDirective,
  MdbTablePaginationComponent,
} from "angular-bootstrap-md";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";
declare var $: any;
import { GlobalConstants } from "../../common/helpers/global_variables";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { HttpProvider } from "../../common/http/http.provider";
import { NgxUiLoaderService } from "ngx-ui-loader";
@Component({
  selector: "app-shipment-moniter",
  templateUrl: "./shipment-moniter.component.html",
  styleUrls: ["./shipment-moniter.component.css"],
})
export class ShipmentMoniterComponent implements OnInit {
  title = "appBootstrap";
  showModel: any = false;
  showcargoDetailModal: any = false;
  submitButton: any = true;
  updateButton: any = false;
  registerForm: FormGroup;
  submitted = false;
  indexvalue: any = "";
  cargo: any = {
    trackno: "",
    status: "",
    averageShipingtime: "",
    shipmentNo: "",
    customerId: "",
  };
  model: NgbDateStruct;
  date: { year: number; month: number };
  dataEntry: any = [];
  closeResult: string;
  faSort = faSort;
  faPlus = faPlus;
  faEdit = faEdit;
  faTimes = faTimes;
  faEye = faEye;
  faCheck = faCheck;
  faCheckDouble = faCheckDouble;
  faHome = faHome;
  faCaretRight = faCaretRight;
  // @HostListener('input') oninput() {
  //   this.searchItems();
  // }
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  elements: any = [];
  isDesc: boolean = false;
  column: string = "CategoryName";
  cargoElements: any = [];
  cargoItems: any = [];
  cargoCheckInData: any = [];
  cargoCheckOutData: any = [];
  page = 1;
  pageSize = 10;
  headElements = [
    "id",
    "shipper information",
    "consignee information",
    "date",
    "tracking number",
    "status",
  ];
  searchText: string = "";
  previous: string;
  deleteoption: any = "";
  cargoheadElements1 = [
    "Pieces",
    "length",
    "weight",
    "height",
    "pounds",
    "cubic feet",
    "charge weight",
    "reference",
  ];
  // indexvalue = 5;
  constructor(
    private modalService: NgbModal,
    private _router: Router,
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    private http: HttpProvider,
    private httpClient: HttpClient,
    private ngxService: NgxUiLoaderService
  ) {
    // if (
    //   canActive("WareHouseUser") == false &&
    //   canActive("WareHouseSupervisor") == false
    // ) {
    //   let str = window.location.href;
    //   let myArr = str.split("#");
    //   window.location.replace(myArr[0] + "#/login");
    // }
    // this.deleteoption = canActive("WareHouseSupervisor") == true;
  }
  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnInit(): void {
    GlobalConstants.cragoId = "";
    this.registerForm = this.formBuilder.group({
      status: ["", Validators.required],
      trackno: ["", Validators.required],
      shipmentno: ["", Validators.required],
      customer: ["", Validators.required],
      shipmentaveragetime: ["", Validators.required],
    });
    this.ngxService.start();
    this.getdata();
    this.getcargoCheckInCheckOut();
    // this.elements.push(this.dataEntry);

    // this.mdbTable.setDataSource(this.elements);
    // this.previous = this.mdbTable.getDataSource();
    // this.cdRef.detectChanges();
  }
  searchItems() {
    // console.log("serchitems");
    // const prev = this.mdbTable.getDataSource();
    // if (!this.searchText) {
    //   this.mdbTable.setDataSource(this.previous);
    //   this.elements = this.mdbTable.getDataSource();
    // }
    // if (this.searchText) {
    //   this.elements = this.mdbTable.searchLocalDataByMultipleFields(this.searchText, ['id', 'track no', 'status', 'average shiping time', 'shipment no', 'customer id']);
    //   this.mdbTable.setDataSource(prev);
    // }
  }
  //Cargo Detail modal open
  onViewItem(e, i) {
    GlobalConstants.cragoId = e.id;
    this.showcargoDetailModal = true;
    this.httpClient
      .get(this.http.BASE_URI + "editCargoEntry/" + e.id)
      .subscribe((response: any) => {
        // console.log("Response", response.cargo_entry);
        this.cargoElements = response.cargo_entry;
        this.cargoItems = response.cargo_items;
        this.ngxService.stop();
      });
  }
  onRowCreate(e, i) {
    GlobalConstants.cragoId = e.id;
    GlobalConstants.cargoEntrySavebtn = false;
    GlobalConstants.cargoEntryUpdatebtn = true;
    this._router.navigateByUrl("/warehouse/cargoentry/air");
    // console.log("e====>", e, i);
  }
  onRowRemove(e, i) {
    // console.log(e);
    this.indexvalue = i;
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this data!",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        this.httpClient
          .delete(this.http.BASE_URI + "deleteCargo/" + e.id)
          .subscribe((response: any) => {
            // console.log("Response", response);
            this.ngxService.start();
            this.getdata();
          });
        Swal.fire("Deleted!", "Your data has been deleted.", "success");
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }
  onSubmitClick() {
    // console.log("onsubmit", this.cargo);
    this.submitted = true;
    // this.submitButton=false;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    this.elements.push(this.cargo);
    //  $("#mymodal").modal("hide");
    Swal.fire("Thank you...", "You submitted succesfully!", "success");
    this.showModel = false;
    //
    //  alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.cargo));
    //    this.cargo={
    //     trackno:"",
    //     status:"",
    //     averageShipingtime:new Date(),
    //     shipmentNo:"",
    //     customerId:""
    // }
    // display form values on success
  }
  onUpdateClick() {
    this.submitted = true;
    // this.submitButton=false;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    this.elements[this.indexvalue] = this.cargo;
    Swal.fire("Thank you...", "Your Data Edited Succesfully!", "success");
    this.showModel = false;
  }
  onReset() {
    this.submitted = false;
    this.registerForm.reset();
    this.updateButton = false;
    this.submitButton = true;
  }
  aftersubmit() {
    $(".modal").modal("hide");
    // $("#myModal").modal("hide");
    Swal.fire("Thank you...", "You submitted succesfully!", "success");
  }
  getdata() {
    this.httpClient
      .get(this.http.BASE_URI + "listCargo/backoffice")
      .subscribe((response: any) => {
        // console.log("Response", response);
        // this.elements = response.all;
        response.all.forEach((element) => {
          this.elements.push(element.details);
        });
        this.ngxService.stop();
      });
  }
  configFormModel() {
    this.onReset();

    // this.showModel = true;
    GlobalConstants.cargoEntrySavebtn = true;
    GlobalConstants.cargoEntryUpdatebtn = false;
    this._router.navigateByUrl("/warehouse/cargoentry/air");
  }
  cancel() {
    this.showModel = false;
    this.showcargoDetailModal = false;
    // this.submitted = false;
    // this.ConfigFormGroup.reset();
  }
  //Entry move to check in
  onCheckIn(e, i) {
    GlobalConstants.cragoId = e.id;
    Swal.fire({
      title: "Are you sure want to move checkin?",
      // text: "You will not be able to recover this data!",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes, Move it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        this.ngxService.start();
        this.httpClient
          .post(this.http.BASE_URI + "cargoCheckInCheckOut/" + e.id, {
            checkin_or_checkout: "checkin",
          })
          .subscribe((response: any) => {
            // console.log("Response", response);
            this.getdata();
            this.getcargoCheckInCheckOut();
            this.ngxService.stop();
          });
        Swal.fire("Check In!", "Your cargo has been check in successfully.", "success");
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  }

  getcargoCheckInCheckOut() {
    this.httpClient
      .get(this.http.BASE_URI + "listCargo/backoffice")
      .subscribe((response: any) => {
        // console.log("Response", response);
        // this.cargoCheckInData = response.air;
        response.air.forEach((element) => {
          this.cargoCheckInData.push(element.details);
        });
      });

    this.httpClient
      .get(this.http.BASE_URI + "listCargo/backoffice")
      .subscribe((response: any) => {
        // console.log("cargoCheckOutData", response.air.checkout);
        // this.cargoCheckOutData = response.ocean;
        response.air.forEach((element) => {
          this.cargoCheckOutData.push(element.details);
        });
      });
  }
  //Entry move to check out
  onCheckOut(e, i) {
    GlobalConstants.cragoId = e.id;
    Swal.fire({
      title: "Are you sure want to move checkout?",
      // text: "You will not be able to recover this data!",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes, Move it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        this.ngxService.start();
        this.httpClient
          .post(this.http.BASE_URI + "cargoCheckInCheckOut/" + e.id, {
            checkin_or_checkout: "checkout",
          })
          .subscribe((response: any) => {
            // console.log("Response", response);
            this.getcargoCheckInCheckOut();
            this.ngxService.stop();
          });
        Swal.fire("Check Out!", "Your cargo has been check out successfully.", "success");
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  }
  
  sort(property) {
    // console.log(property)
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;
    this.elements.sort(function (a, b) {
      if (a[property].toLowerCase() < b[property].toLowerCase()) {
        return -1 * direction;
      }
      else if (a[property].toLowerCase() > b[property].toLowerCase()) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  sort1(property) {
    // console.log(property)
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;
    this.cargoCheckInData.sort(function (a, b) {
      if (a[property].toLowerCase() < b[property].toLowerCase()) {
        return -1 * direction;
      }
      else if (a[property].toLowerCase() > b[property].toLowerCase()) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  sort2(property) {
    // console.log(property)
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;
    this.cargoCheckOutData.sort(function (a, b) {
      if (a[property].toLowerCase() < b[property].toLowerCase()) {
        return -1 * direction;
      }
      else if (a[property].toLowerCase() > b[property].toLowerCase()) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
}
