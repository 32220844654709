import { Component, OnInit } from '@angular/core';
import { faHome , faCaretRight} from "@fortawesome/free-solid-svg-icons";
import {​​​​​​SidenavComponent}​​​​​​ from "../sidenav/sidenav.component";
import {​​​​​​ Router }​​​​​​ from "@angular/router";

@Component({
  selector: 'app-backofficeuser-reporting',
  templateUrl: './backofficeuser-reporting.component.html',
  styleUrls: ['./backofficeuser-reporting.component.css']
})
export class BackofficeuserReportingComponent implements OnInit {
  faHome = faHome;
  faCaretRight = faCaretRight;
  constructor(private component:SidenavComponent,private _router: Router) { }

  ngOnInit(): void {
  }
homeLink(){
this.component.ngOnInit();
if(localStorage.getItem('Role')=='Admin'){
this._router.navigateByUrl("/adminDashboard");
}else{
this._router.navigateByUrl("/backofficeDashboard");
}
}
}
