import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import {
  faSort,
  faPlus,
  faEdit,
  faTimes,
  faHome,
  faCaretRight,
} from "@fortawesome/free-solid-svg-icons";

import { HttpClient } from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { HttpProvider } from "../../common/http/http.provider";
import { HttpHeaders } from "@angular/common/http";
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { SidenavComponent } from "../sidenav/sidenav.component";
import { Router } from "@angular/router";
declare var $: any;
@Component({
  selector: "app-customersupport-invoice",
  templateUrl: "./customersupport-invoice.component.html",
  styleUrls: ["./customersupport-invoice.component.css"],
})
export class CustomersupportInvoiceComponent implements OnInit {
  faSort = faSort;
  faHome = faHome;
  faCaretRight = faCaretRight;
  isDesc: boolean = false;
  column: string = "CategoryName";
  page = 1;
  pageSize = 10;
  numberOfRecords: number;
  headElements = [
    "invoice id",
    "customer name",
    "invoice date",
    "due date",
    "amount",
  ];
  searchText: string = "";
  previous: string;
  elements: any = [];
  constructor(
    private http: HttpProvider,
    private httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private component: SidenavComponent,
    private _router: Router
  ) {}

  ngOnInit(): void {
    this.ngxService.start();
    this.getdata();
  }
  homeLink() {
    this.component.ngOnInit();
    if (localStorage.getItem("Role") == "Admin") {
      this._router.navigateByUrl("/adminDashboard");
    } else {
      this._router.navigateByUrl("/backofficeDashboard");
    }
  }
  getdata() {
    this.httpClient
      .get(this.http.BASE_URI + "cargo/fetchInvoice")
      .subscribe((response: any) => {
        // console.log("Response", response)
        this.elements = Object.values(response.message);
        this.loadPage(this.page);
        // console.log(Object.values(this.elements));
        this.ngxService.stop();
      });
  }
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;
    this.elements.sort(function (a, b) {
      if (a[property].toLowerCase() < b[property].toLowerCase()) {
        return -1 * direction;
      }
      else if (a[property].toLowerCase() > b[property].toLowerCase()) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  sort1(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;
    this.elements.sort(function (a, b) {
    if (parseInt(a[property]) < parseInt(b[property])) {
    return -1 * direction;
    }
    else if (parseInt(a[property]) > parseInt(b[property])) {
    return 1 * direction;
    }
    else {
    return 0;
    }
    });
    };
    //showing pagination count
    loadPage(e){
      this.numberOfRecords = (e - 1) * this.pageSize + this.pageSize > this.elements.length ? this.elements.length :  (e - 1) * this.pageSize + this.pageSize;
    }
}
