import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  ElementRef
} from "@angular/core";
import {
  faSort,
  faPlus,
  faEdit,
  faEye,
  faCheck,
  faCheckDouble,
  faTimes,
  faHome,
  faCaretRight,
  faSyncAlt,
  faClock,
  faShareSquare,
  faPlane,
  faMinus,
  faCheckSquare,
} from "@fortawesome/free-solid-svg-icons";
import {
  MdbTableDirective,
  MdbTablePaginationComponent,
} from "angular-bootstrap-md";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";
declare var $: any;
import { GlobalConstants } from "../../common/helpers/global_variables";
import { Router } from "@angular/router";
import { HttpClient } from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { HttpProvider } from "../../common/http/http.provider";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { SidenavComponent } from "../sidenav/sidenav.component";
import { SelectionModel } from "@angular/cdk/collections";
@Component({
  selector: "app-warehouse-dataentry-oceancargo",
  templateUrl: "./warehouse-dataentry-oceancargo.component.html",
  styleUrls: ["./warehouse-dataentry-oceancargo.component.css"],
})
export class WarehouseDataentryOceancargoComponent implements OnInit {
  title = "appBootstrap";
  showModel: any = false;

  showStatusModal: any = false;
  showcargoDetailModal: any = false;
  submitButton: any = true;
  updateButton: any = false;
  registerForm: FormGroup;
  statusBatchForm: FormGroup;
  submitted = false;
  indexvalue: any = "";
  Log: any = "";
  showLogModel: boolean = false;

   status: any = {
    id: "",
    name: "",
  };
  cargo: any = {
    trackno: "",
    status: "",
    averageShipingtime: "",
    shipmentNo: "",
    customerId: "",
  };
  batchForm: FormGroup;
  batch_submitted = false;
  model: NgbDateStruct;
  date: { year: number; month: number };
  dataEntry: any = [];
  closeResult: string;
  faSort = faSort;
  faPlus = faPlus;
  faEdit = faEdit;
  faTimes = faTimes;
  faEye = faEye;
  faCheck = faCheck;
  faCheckDouble = faCheckDouble;
  faHome = faHome;
  faCaretRight = faCaretRight;
  faSyncAlt = faSyncAlt;
  faClock = faClock;
  faShareSquare = faShareSquare;
  faPlane = faPlane;
  faMinus = faMinus;
  faCheckSquare = faCheckSquare;
  batchDetails: any = {
    batch_name: "",
    Eta: "",
  };
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild('privatUserCheckbox') privatUserCheckbox: ElementRef;
  selection = new SelectionModel(true, []);
  selectionAmount: number;
  spinner: boolean = false;
  elements: any = [];
  isDesc: boolean = false;
  column: string = "CategoryName";
  cargoElements: any = [];
  cargoCheckInData: any = [];
  cargoCheckOutData: any = [];
  cargoItems: any = [];
  cargoPendingData: any = [];
  cargoMiamiWhData: any = [];

  cargoElementsPage = 1;
  cargoCheckInPage = 1;
  cargoCheckOutPage = 1;
  cargoPendingPage = 1;
  cargoMiamiWhPage = 1;

  page = 1;
  pageSize = 25;
  noOfRecordsInCurrentPage = 10;

  numberOfElementsRecords: number;
  numberOfCheckInRecords: number;
  numberOfCheckOutRecords: number;
  numberofPendingRecords:number;
  numberofMiamiWhRecords:number;

  totalOceanCount: number;
  totalPendingRecords:number;
  totalCheckInRecords:number;
  totalCheckOutRecords:number;
  totalMiamiWhRecords:number;

  headElements = [
    "id",
    "shipper information",
    "consignee information",
    "date",
    "tracking number",
    "status",
  ];
  cargoheadElements1 = [
    "Pieces",
    "length",
    "weight",
    "height",
    "pounds",
    "cubic feet",
    "cw",
    "reference",
  ];
  searchText: string = "";
  previous: string;
  deleteoption: any = "";
  LogLength: any = "";
  showBatchModal: boolean = false;
  // indexvalue = 5;
  constructor(
    private modalService: NgbModal,
    private _router: Router,
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    private http: HttpProvider,
    private httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private component: SidenavComponent
  ) {}
  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  emailCargoFetch() {
    // this.ngxService.start();
    this.spinner = true;
    this.httpClient
      .get(this.http.BASE_URI + "fetchMailCargo")
      .subscribe((response: any) => {
        var status = response.success;
        if (status) {
          Swal.fire("", "Cargo synced successfully", "success");
        }
        this.spinner = false;
        //  console.log("Response", response);
        this.getdata();
        this.getcargoCheckInCheckOut();
        // this.ngxService.stop();
      });
  }
  ngOnInit(): void {
    if (
      localStorage.getItem("Role") == "WareHouseSupervisor" ||
      localStorage.getItem("Role") == "Admin"
    ) {
      this.deleteoption = true;
    }
    this.registerForm = this.formBuilder.group({
      status: ["", Validators.required],
      trackno: ["", Validators.required],
      shipmentno: ["", Validators.required],
      customer: ["", Validators.required],
      shipmentaveragetime: ["", Validators.required],
    });
    this.batchForm = this.formBuilder.group({
      batch_name: ["", Validators.required],
      Eta: ["", Validators.required],
    });
    this.statusBatchForm = this.formBuilder.group({
      name: ["", Validators.required],
    });
    this.ngxService.start();
    this.getdata();
    this.getcargoCheckInCheckOut();
    this.getCargoPendingData();
    this.getCargoMiamiWHData();
    // this.elements.push(this.dataEntry);

    // this.mdbTable.setDataSource(this.elements);
    // this.previous = this.mdbTable.getDataSource();
    // this.cdRef.detectChanges();
  }
  searchItems() {
    // console.log("serchitems");
    // const prev = this.mdbTable.getDataSource();
    // if (!this.searchText) {
    //   this.mdbTable.setDataSource(this.previous);
    //   this.elements = this.mdbTable.getDataSource();
    // }
    // if (this.searchText) {
    //   this.elements = this.mdbTable.searchLocalDataByMultipleFields(this.searchText, ['id', 'track no', 'status', 'average shiping time', 'shipment no', 'customer id']);
    //   this.mdbTable.setDataSource(prev);
    // }
  }
  onRowCreate(e, i) {
    GlobalConstants.cragoId = e.id;
    GlobalConstants.cargoEntrySavebtn = false;
    GlobalConstants.cargoEntryUpdatebtn = true;
    this._router.navigateByUrl("/warehouse/cargoentry/ocean/" + e.id);
    // console.log("e====>", e, i);
  }
  onLog(el, i) {
    // console.log('el',el)
    this.ngxService.start();
    this.httpClient
      .get(this.http.BASE_URI + "cargoLogs/" + el.id)
      .subscribe((response: any) => {
        // console.log(response)
        this.Log = response;
        this.LogLength = response.status.length;
        this.ngxService.stop();
      });
    this.showLogModel = true;
  }
  onBatch() {
    this.batch_submitted = false;
    this.batchForm.reset();
    this.showBatchModal = true;
  }
  onRowRemove(e, i) {
    // console.log(e);
    this.indexvalue = i;
    Swal.fire({
      title: "Are you sure want to delete this record?",
      text: "You will not be able to recover this data!",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.httpClient
          .post(this.http.BASE_URI + "deleteCargo/" + e.id,'')
          .subscribe((response: any) => {
            // console.log("Response", response);
            this.ngxService.start();
            this.getdata();
          });
        Swal.fire("Deleted!", "Your data has been deleted.", "success");
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  }
  // convenience getter for easy access to form fields
  get f() {
    return this.registerForm.controls;
  }
  get b() {
    return this.batchForm.controls;
  }
  get s() {
    return this.statusBatchForm.controls;
  }
  async onBatchSubmitClick() {
    this.batch_submitted = true;
    if (this.batchForm.invalid) {
      return;
    }
    this.httpClient
      .post(this.http.BASE_URI + "cargo/addBatchOption", {
        batch_name: this.batchDetails.batch_name,
        eta_date: this.batchDetails.Eta,
        cargo_ids: await this.logSelection(),
      })
      .subscribe((response: any) => {
        this.ngxService.start();
        this.showBatchModal = false;
        this.privatUserCheckbox["checked"] = false;
        this.privatUserCheckbox["indeterminate"] = false;
        this.selection = new SelectionModel(true, []);
        this.getdata();
        this.getcargoCheckInCheckOut();
        Swal.fire("", "Batch Option Added Successfully..", "success");
        this.ngxService.stop();
      });
  }
  onSubmitClick() {
    // console.log("onsubmit", this.cargo);
    this.submitted = true;
    // this.submitButton=false;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    this.elements.push(this.cargo);
    //  $("#mymodal").modal("hide");
    Swal.fire("Thank you...", "You submitted succesfully!", "success");
    this.showModel = false;
    //
    //  alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.cargo));
    //    this.cargo={
    //     trackno:"",
    //     status:"",
    //     averageShipingtime:new Date(),
    //     shipmentNo:"",
    //     customerId:""
    // }
    // display form values on success
  }
  //Cargo Detail modal open
  onViewItem(e, i) {
    GlobalConstants.cragoId = e.id;
    this.showcargoDetailModal = true;
    this.httpClient
      .get(this.http.BASE_URI + "editCargoEntry/" + e.id)
      .subscribe((response: any) => {
        // console.log("Response", response.cargo_entry);
        this.cargoElements = response.cargo_entry;
        this.cargoItems = response.cargo_items;
        this.ngxService.stop();
      });
  }

  onUpdateClick() {
    this.submitted = true;
    // this.submitButton=false;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    this.elements[this.indexvalue] = this.cargo;
    Swal.fire("Thank you...", "Your Data Edited Succesfully!", "success");
    this.showModel = false;
  }
  onReset() {
    this.submitted = false;
    this.registerForm.reset();
    this.updateButton = false;
    this.submitButton = true;
  }
  aftersubmit() {
    $(".modal").modal("hide");
    // $("#myModal").modal("hide");
    Swal.fire("Thank you...", "You submitted succesfully!", "success");
  }
  getdata(e = 1) {
    this.httpClient
      .get(this.http.BASE_URI + "listCargo/portal?page=" +this.page)
      .subscribe((response: any) => {
        // console.log("Response", response);
        // this.elements = response.ocean;
        this.elements = [];
        this.totalOceanCount = response.ocean.totalOceanCount;
        response.ocean.data.forEach((element) => {
          element.details.pcs = 0;
          element.details.weight = 0;
          element.details.cubic_feet = 0;
          element.items[0].forEach(async (item) => {
            item.weight = (item.weight == null) ? 0 : item.weight;
            element.details.pcs += parseInt(item.pcs);
            element.details.weight += parseInt(item.weight);
            element.details.cubic_feet += parseFloat(item.cubic_feet);
          });
          this.elements.push(element.details);
        });
         
         this.numberOfElementsRecords =
       (this.page - 1) * this.pageSize + this.pageSize > this.elements.length
        ? this.elements.length
        : (this.page - 1) * this.pageSize + this.pageSize;
      //  this.loadElementsPage(this.cargoElementsPage);
      //  this.loadCheckInPage(this.cargoCheckInPage);
        this.ngxService.stop();
      });
  }

  getCargoPendingData(e = 1){
    this.httpClient
        .get(this.http.BASE_URI + "listCargo/pending/ocean?page="+this.page)
        .subscribe((response: any) => {
          this.cargoPendingData = [];
          this.totalPendingRecords = response.ocean.totalCount;
          response.ocean.data.forEach(async (element) => {
            // console.log('Elements',element)
            element.details.pcs = 0;
            element.details.weight = 0;
            element.details.cubic_feet = 0;
            element.items[0].forEach(async (item) => {
              item.weight = item.weight == null ? 0 : item.weight;
              element.details.pcs += parseInt(item.pcs);
              element.details.weight += parseInt(item.weight);
              element.details.cubic_feet += parseFloat(item.cubic_feet);
            });
            // console.log(this.elements);
            this.cargoPendingData.push(element.details);
          });
         // this.loadPendingPage(this.cargoPendingPage);
          // console.log(this.elements)
          this.ngxService.stop();
        });
    }

  getCargoMiamiWHData(e =1 ){
  this.httpClient
      .get(this.http.BASE_URI + "listCargo/miami/ocean?page=" + this.page)
      .subscribe((response: any) => {
        this.cargoMiamiWhData = [];
        this.totalMiamiWhRecords = response.ocean.totalCount;
        response.ocean.data.forEach(async (element) => {
          // console.log('Elements',element)
          element.details.pcs = 0;
          element.details.weight = 0;
          element.details.cubic_feet = 0;
          element.items[0].forEach(async (item) => {
            item.weight = item.weight == null ? 0 : item.weight;
            element.details.pcs += parseInt(item.pcs);
            element.details.weight += parseInt(item.weight);
            element.details.cubic_feet += parseFloat(item.cubic_feet);
          });
          // console.log(this.elements);
          this.cargoMiamiWhData.push(element.details);
        });
       // this.loadPendingPage(this.cargoPendingPage);
        // console.log(this.elements)
        this.ngxService.stop();
      });
  }

  configFormModel() {
    this.onReset();

    // this.showModel = true;
    GlobalConstants.cargoEntrySavebtn = true;
    GlobalConstants.cargoEntryUpdatebtn = false;
    this._router.navigateByUrl("/warehouse/cargoentry/ocean");
  }
  cancel() {
    this.showModel = false;
    this.showcargoDetailModal = false;
    this.showLogModel = false;
    this.showBatchModal = false;
    // this.submitted = false;
    // this.ConfigFormGroup.reset();
  }
  //Entry move to check in
  onCheckIn(e, i) {
    GlobalConstants.cragoId = e.id;
    Swal.fire({
      title: "Are you sure  move to checkin?",
      // text: "You will not be able to recover this data!",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        let data = { checkin: "checkin" };
        this.httpClient
          .post(this.http.BASE_URI + "cargoCheckInCheckOut/" + e.id, {
            checkin_or_checkout: "checkin",
            created_by: localStorage.getItem("Id"),
          })
          .subscribe((response: any) => {
            // console.log("Response", response);
            this.ngxService.start();
            this.getdata();
            this.getcargoCheckInCheckOut();
            this.getCargoPendingData();
            this.getCargoMiamiWHData();
          });
        Swal.fire(
          "Check In!",
          "Your cargo has been check in successfully.",
          "success"
        );
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
      this.ngxService.stop();
    });
  }
  shipmentChange(e, i) {
    GlobalConstants.cragoId = e.id;
    Swal.fire({
      title: "Are you sure want to change shipment type?",
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        this.ngxService.start();
        this.httpClient
          .post(this.http.BASE_URI + "shippingType", {
            cargo_id: e.id,
            old_cargo_type: "ocean",
            new_cargo_type: "air",
            status: "request",
            sent_by_user: localStorage.getItem("Id"),
          })
          .subscribe((response: any) => {
            this.getdata();
            this.ngxService.stop();
          });
        Swal.fire(
          "",
          "Shipment type change request sent successfully",
          "success"
        );
      } else if (result.dismiss === Swal.DismissReason.cancel) {
      }
    });
  }
  getcargoCheckInCheckOut(e = 1) {
    this.httpClient
      .get(this.http.BASE_URI + "getcargoCheckInCheckOut/ocean/checkin?page="+this.page)
      .subscribe((response: any) => {
        // console.log("Response", response);
        this.cargoCheckInData = [];
        this.totalCheckInRecords = response.ocean.checkin.totalCount;
        response.ocean.checkin.data.forEach((element) => {
          element.details.pcs = 0;
          element.details.weight = 0;
          element.details.cubic_feet = 0;
          element.items[0].forEach(async (item) => {
            item.weight = item.weight == null ? 0 : item.weight;
            element.details.pcs += parseInt(item.pcs);
            element.details.weight += parseInt(item.weight);
            element.details.cubic_feet += parseFloat(item.cubic_feet);
          });
          this.cargoCheckInData.push(element.details);
        });
         
        this.numberOfCheckInRecords =
      (this.page - 1) * this.pageSize + this.pageSize > this.cargoCheckInData.length
        ? this.cargoCheckInData.length
        : (this.page - 1) * this.pageSize + this.pageSize;

        //this.loadCheckInPage(this.cargoCheckInPage);
        // this.cargoCheckInData = response.ocean.checkin;
      });

    this.httpClient
      .get(this.http.BASE_URI + "getcargoCheckInCheckOut/ocean/checkout?page="+this.page)
      .subscribe((response: any) => {
        // console.log("cargoCheckOutData", response.ocean.checkout);
        // this.cargoCheckOutData = response.ocean.checkout;
        this.cargoCheckOutData = [];
        this.totalCheckOutRecords = response.ocean.checkout.totalCount

        response.ocean.checkout.data.forEach((element) => {
          element.details.pcs = 0;
          element.details.weight = 0;
          element.details.cubic_feet = 0;
          element.items[0].forEach(async (item) => {
            item.weight = item.weight == null ? 0 : item.weight;
            element.details.pcs += parseInt(item.pcs);
            element.details.weight += parseInt(item.weight);
            element.details.cubic_feet += parseFloat(item.cubic_feet);
          });
          this.cargoCheckOutData.push(element.details);
        });
         
         this.numberOfCheckOutRecords =
      (this.page - 1) * this.pageSize + this.pageSize > this.cargoCheckOutData.length
        ? this.cargoCheckOutData.length
        : (this.page - 1) * this.pageSize + this.pageSize;
        //this.loadCheckOutPage(this.cargoCheckOutPage);
      });
  }

  //Entry move to check out
  onCheckOut(e, i) {
    GlobalConstants.cragoId = e.id;
    Swal.fire({
      title: "Are you sure move to checkout?",
      // text: "You will not be able to recover this data!",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.ngxService.start();
        this.httpClient
          .post(this.http.BASE_URI + "cargoCheckInCheckOut/" + e.id, {
            checkin_or_checkout: "checkout",
            created_by: localStorage.getItem("Id"),
          })
          .subscribe((response: any) => {
            // console.log("Response", response);
            this.getcargoCheckInCheckOut();
            this.getCargoPendingData();
            this.getCargoMiamiWHData();
            this.ngxService.stop();
          });
        Swal.fire("Check Out!", "Your cargo has been check out.", "success");
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  }
  sortNumber(property, e) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;
    if (e == "all") {
      this.elements.sort(function (a, b) {
        a[property] = a[property] == null ? " " : a[property];
        b[property] = b[property] == null ? " " : b[property];
        if (parseInt(a[property]) < parseInt(b[property])) {
          return -1 * direction;
        } else if (parseInt(a[property]) > parseInt(b[property])) {
          return 1 * direction;
        } else {
          return 0;
        }
      });
    } else if (e == "checkin") {
      this.cargoCheckInData.sort(function (a, b) {
        a[property] = a[property] == null ? " " : a[property];
        b[property] = b[property] == null ? " " : b[property];
        if (parseInt(a[property]) < parseInt(b[property])) {
          return -1 * direction;
        } else if (parseInt(a[property]) > parseInt(b[property])) {
          return 1 * direction;
        } else {
          return 0;
        }
      });
    } else {
      this.cargoCheckOutData.sort(function (a, b) {
        a[property] = a[property] == null ? " " : a[property];
        b[property] = b[property] == null ? " " : b[property];
        if (parseInt(a[property]) < parseInt(b[property])) {
          return -1 * direction;
        } else if (parseInt(a[property]) > parseInt(b[property])) {
          return 1 * direction;
        } else {
          return 0;
        }
      });
    }
  }
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.elements.sort(function (a, b) {
      a[property] = a[property] == null ? " " : a[property];
      b[property] = b[property] == null ? " " : b[property];
      if (a[property].toLowerCase() < b[property].toLowerCase()) {
        return -1 * direction;
      } else if (a[property].toLowerCase() > b[property].toLowerCase()) {
        return 1 * direction;
      } else {
        return 0;
      }
    });
  }
  sort1(property) {
    // console.log(property)
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;
    this.cargoCheckInData.sort(function (a, b) {
      a[property] = a[property] == null ? " " : a[property];
      b[property] = b[property] == null ? " " : b[property];
      if (a[property].toLowerCase() < b[property].toLowerCase()) {
        return -1 * direction;
      } else if (a[property].toLowerCase() > b[property].toLowerCase()) {
        return 1 * direction;
      } else {
        return 0;
      }
    });
  }
  sort2(property) {
    // console.log(property)
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;
    this.cargoCheckOutData.sort(function (a, b) {
      a[property] = a[property] == null ? " " : a[property];
      b[property] = b[property] == null ? " " : b[property];
      if (a[property].toLowerCase() < b[property].toLowerCase()) {
        return -1 * direction;
      } else if (a[property].toLowerCase() > b[property].toLowerCase()) {
        return 1 * direction;
      } else {
        return 0;
      }
    });
  }
  sortdate(property) {
    // console.log(property)
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;
    this.cargoCheckOutData.sort(function (a, b) {
      a[property] = a[property] == null ? "" : a[property];
      b[property] = b[property] == null ? "" : b[property];
      if (a[property].toLowerCase() < b[property].toLowerCase()) {
        return -1 * direction;
      } else if (a[property].toLowerCase() > b[property].toLowerCase()) {
        return 1 * direction;
      } else {
        return 0;
      }
    });
  }
  homeLink() {
    this.component.ngOnInit();
    if (localStorage.getItem("Role") == "Admin") {
      this._router.navigateByUrl("/adminDashboard");
    } else {
      this._router.navigateByUrl("/backofficeDashboard");
    }
  }
  //showing pagination records count
  loadElementsPage(page) {
    this.page = page;
    this.getdata();
  }

  loadCheckInPage(page) {
    this.page = page
    this.getcargoCheckInCheckOut();

  }
  loadCheckOutPage(page) {
   this.page = page;
   this.getcargoCheckInCheckOut();
  }

  loadPendingPage(page){
    this.page = page
    this.getCargoPendingData();
  }

  loadMiamiWhPage(page){
    this.numberofMiamiWhRecords =
    (page - 1) * this.pageSize + this.pageSize > this.cargoMiamiWhData.length
      ? this.cargoMiamiWhData.length
      : (page - 1) * this.pageSize + this.pageSize;
    this.page = page
    this.getCargoMiamiWHData();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.cargoCheckOutData.length;
    return numSelected === numRows;
  }
  isSelectedPage() {
    const numSelected = this.selection.selected.length;
    let endIndex: number;
    endIndex = this.cargoCheckOutData.length;
    return numSelected === endIndex;
  }
  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.cargoCheckOutData.forEach((row) => this.selection.select(row));
  }

  logSelection() {
    let id = [];
    this.selection.selected.forEach(async (s) => await id.push(s.id));
    return id;
  }

  masterStatusToggle() {
    this.isAllStatusSelected()
      ? this.clearAll()
      :  this.selectRows();
  }

   /**Pending tab batch status**/
   masterPendingStatusToggle() {
    this.isPendingStatusSelected()
      ? this.clearAll()
      :  this.selectPendingRows();
  }

  isPendingStatusSelected(){

    const numStatusSelected = this.selection.selected.length;
    const page = this.pageSize;
    let x = this.numberofPendingRecords % this.pageSize;
    if(x != 0){
      this.noOfRecordsInCurrentPage = x;
    }else{
      this.noOfRecordsInCurrentPage = this.pageSize;
    }

    if(numStatusSelected == this.noOfRecordsInCurrentPage){
      return true;
    }else{
      return false;
    }

  }

  isAnyPendingChecked(){

    if(this.selection.selected.length > 0){
      return true;
    }else{
      return false;
    }
  } 

  selectPendingRows(){
     this.selection.clear();
    let start = (this.pageSize * (this.page - 1));
    let end = (this.numberofPendingRecords)
    for (let index = 0; index < this.pageSize; index++) {
      this.selection.select(this.cargoPendingData[index]);
      this.selectionAmount = this.selection.selected.length;
    }
  }

  /*MIAMI WH tab batch status*/

   masterMiamiStatusToggle() {
    this.isMiamiStatusSelected()
      ? this.clearAll()
      :  this.selectMiamiRows();
  }

  isMiamiStatusSelected(){

    const numStatusSelected = this.selection.selected.length;
    const page = this.pageSize;
    let x = this.numberofMiamiWhRecords % this.pageSize;
    if(x != 0){
      this.noOfRecordsInCurrentPage = x;
    }else{
      this.noOfRecordsInCurrentPage = this.pageSize;
    }

    if(numStatusSelected == this.noOfRecordsInCurrentPage){
      return true;
    }else{
      return false;
    }

  }

  isAnyMiamiChecked(){
   if(this.selection.selected.length > 0){
      return true;
    }else{
      return false;
    } 
  }

  selectMiamiRows(){
       this.selection.clear();
    let start = (this.pageSize * (this.page - 1));
    let end = (this.numberofMiamiWhRecords)
    for (let index = 0; index < this.pageSize; index++) {
      this.selection.select(this.cargoMiamiWhData[index]);
      this.selectionAmount = this.selection.selected.length;
    }
  }

  masterCheckinToggle(){

    this.isAllCheckinSelected()
      ? this.clearAll()
      :  this.selectCheckinRows();

  }

  isAllStatusSelected() { 

    const numStatusSelected = this.selection.selected.length;
    const page = this.pageSize;
    let x = this.numberOfElementsRecords % this.pageSize;
    if(x != 0){
      this.noOfRecordsInCurrentPage = x;
    }else{
      this.noOfRecordsInCurrentPage = this.pageSize;
    }

    if(numStatusSelected == this.noOfRecordsInCurrentPage){
      return true;
    }else{
      return false;
    }
    //return numSelected === page;
  }

  isAllCheckinSelected(){
    
    const numStatusSelected = this.selection.selected.length;
    const page = this.pageSize;
    let x = this.numberOfCheckInRecords % this.pageSize;

    if(x != 0){
      this.noOfRecordsInCurrentPage = x;
    }else{
      this.noOfRecordsInCurrentPage = this.pageSize;
    }
    if(numStatusSelected == this.noOfRecordsInCurrentPage){
      return true;
    }else{
      return false;
    }
  }
  
  onStatusBatch() {
    this.showStatusModal = true;
  }

  clearAll(){
    this.selection.clear();
  }

  selectRows() {
    this.selection.clear();

    let start = (this.pageSize * (this.page - 1));
    let end = (this.numberOfElementsRecords)
    for (let index = 0; index < this.pageSize; index++) {
      this.selection.select(this.elements[index]);
      this.selectionAmount = this.selection.selected.length;
    }
  }

  selectCheckinRows(){
    this.selection.clear();
    let start = (this.pageSize * (this.page - 1));
    let end = (this.numberOfCheckInRecords)
    for (let index = 0; index < this.pageSize; index++) {
      this.selection.select(this.cargoCheckInData[index]);
      this.selectionAmount = this.selection.selected.length;
    }
  }

  logStatusSelection() {
    
    let id = [];
    this.selection.selected.forEach(async (s) => await id.push(s.id));
    return id;
  }

  addRemove(e,el,i){
    const isChecked = e.checked; // stored checked value true or false
    if(isChecked) {
      this.selection.selected.push(el);
    }else{
       this.selection.selected.splice(i, 1);
    }
  }

  isAnyItemChecked(){

    if(this.selection.selected.length > 0){
      return true;
    }else{
      return false;
    }
  }



  isAnyCheckInItemChecked(){
    if(this.selection.selected.length > 0){
      return true;
    }else{
      return false;
    }
  }

  updateBatchStatus(){
    this.submitted = true;
    if (this.statusBatchForm.invalid) {
      return;
    }
      //this will work on batch option
    //  let header = new HttpHeaders();
    //  header.set("Access-Control-Allow-Origin", "*");
      this.httpClient
      .post(this.http.BASE_URI + "status/addBatchOption", {
        status: this.status.name,
        cargo_ids: this.logStatusSelection(),
      })
      .subscribe((response: any) => {
        this.ngxService.start();
        this.showStatusModal = false;
        this.privatUserCheckbox["checked"] = false;
        this.privatUserCheckbox["indeterminate"] = false;
        this.selection = new SelectionModel(true, []);
        this.getdata();
        this.getcargoCheckInCheckOut();
        this.getCargoPendingData();
        this.getCargoMiamiWHData();
        Swal.fire("", "Batch Option Added Successfully..", "success");
        this.ngxService.stop();
        this.onReset();
        this.cancel();
      });
      return;
  }
  closeStatus(){
    this.showStatusModal = false;
  }
}
