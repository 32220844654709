// import { Component, OnInit } from '@angular/core';
import { Component, ViewChild, OnInit } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { MaterialModule } from "src/app/common/material.module";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { HttpClient } from "@angular/common/http";
import { HttpProvider } from "../../common/http/http.provider";
import {​​​​​​SidenavComponent}​​​​​​ from "../sidenav/sidenav.component";
import {​​​​​​ Router }​​​​​​ from "@angular/router";
import { NgxUiLoaderService } from "ngx-ui-loader";
import {
  faSort,
  faPlus,
  faEdit,
  faTimes,
  faHome,
  faCaretRight
} from "@fortawesome/free-solid-svg-icons";
//import { Label } from 'ng2-charts';
// import {SharedModule} from '../../common/shared.module'
/**
 * @title Autosize sidenav
 */
@Component({
  selector: 'app-admin-dashboard',
  templateUrl: './admin-dashboard.component.html',
  styleUrls: ['./admin-dashboard.component.css']
})
export class AdminDashboardComponent implements OnInit {
  
  constructor(private http: HttpProvider, private httpClient: HttpClient,private component:SidenavComponent,private _router: Router,
    private ngxService: NgxUiLoaderService
    
    ) {
  }

  faSort = faSort;
  faPlus = faPlus;
  faEdit = faEdit;
  faTimes = faTimes;
  faHome = faHome;
  faCaretRight = faCaretRight;
  ngOnInit(): void {
    this.getCargoCount();
  }

homeLink(){
this.component.ngOnInit();
if(localStorage.getItem('Role')=='Admin'){
this._router.navigateByUrl("/adminDashboard");
}else{
this._router.navigateByUrl("/backofficeDashboard");
}
}
  cargoCount: any = [];
  name = "Angular";
  width: number = 700;
  height: number = 300;
  fitContainer: boolean = false;

  view: any[] = [600, 400];
  // options for the chart
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = "Days";
  showYAxisLabel = true;
  yAxisLabel = "Cargo Count";
  timeline = true;
  doughnut = true;
  title = "Cargo";
  colorScheme = {
    domain: ["#9370DB", "#87CEFA", "#FA8072", "#FF7F50", "#90EE90", "#9370DB"],
  };
  //pie
  showLabels = true;
  public multi = [
    {
      name: "Monday",
      series: [
        {
          name: "Air",
          value: 12,
        },
        {
          name: "Ocean",
          value: 32,
        },
      ],
    },
    {
      name: "Tuesday",
      series: [
        {
          name: "Air",
          value: 7,
        },
        {
          name: "Ocean",
          value: 9,
        },
      ],
    },
    {
      name: "Wednesday",
      series: [
        {
          name: "Air",
          value: 54,
        },
        {
          name: "Ocean",
          value: 32,
        },
      ],
    },
    {
      name: "Thursday",
      series: [
        {
          name: "Air",
          value: 76,
        },
        {
          name: "Ocean",
          value: 19,
        },
      ],
    },
    {
      name: "Friday",
      series: [
        {
          name: "Air",
          value: 4,
        },
        {
          name: "Ocean",
          value: 45,
        },
      ],
    },
  ];
  FaBars = faBars;
  @ViewChild("sidenav") sidenav: MatSidenav;

  isExpanded = true;
  showSubmenu: boolean = true;
  isShowing = false;
  showSubSubMenu: boolean = true;
  /*
    getdata() {
    this.httpClient
      .get(this.http.BASE_URI + "getCargoCount")
      .subscribe((response: any) => {
        this.lastweek_ocean = response.ocean_day_wise_result.ocean;
        this.lastweek_air = response.air_day_wise_result.air;
        console.log(this.lastweek_air);
        let days = [
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday"
        ];
        let obj = {
          name: "",
          series: [
            {
              name: "Air",
              value: "",
            },
            {
              name: "Ocean",
              value: "",
            },
          ]
          }
        days.forEach(async element => {
          obj.name = element;
          if(this.lastweek_air[element] == undefined) {
            console.log('Test')
             obj.series[0]["value"] = "0";
          } else {
            let j=this.lastweek_air[element]
             obj.series[0]["value"] =j;
            console.log('obj.series[0]["value"]',obj.series[0]["value"] )
          }
          const data ={
            ...obj,
          };
          this.array.push(data);
        });
      });
  }
  */
  mouseenter() {
    // if (!this.isExpanded) {
    //   this.isShowing = true;
    // }
  }

  mouseleave() {
    //   if (!this.isExpanded) {
    //     this.isShowing = false;
    //   }
  }
  getCargoCount() {
    this.httpClient
      .get(this.http.BASE_URI + "getCargoCount")
      .subscribe((response: any) => {
        // console.log("Response", response);
        this.cargoCount = response;
      });
  }

}
