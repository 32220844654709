import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import {
  MdbTableDirective,
  MdbTablePaginationComponent,
} from "angular-bootstrap-md";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { GlobalConstants } from "../../common/helpers/global_variables";
import { Router } from "@angular/router";
import { MatTable } from "@angular/material/table";
import { HttpClient } from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { HttpProvider } from "../../common/http/http.provider";
import { NgxUiLoaderService } from "ngx-ui-loader";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  faCog,
  faPlus,
  faSearch,
  faBars,
  faEye,
  faTachometerAlt,
  faEdit,
  faSort,
  faCheck,
  faCheckDouble,
  faOutdent,
  faFileInvoice,
  faShoppingBag,
  faFile,
  faIdBadge,
  faStickyNote,
  faUser,
  faCaretDown,
  faCaretUp,
  faSignOutAlt,
  faCaretRight,
  faHome,
  faCamera,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import { SidenavComponent } from "../sidenav/sidenav.component";
@Component({
  selector: "app-warehouse-cargo-entry",
  templateUrl: "./warehouse-cargo-entry.component.html",
  styleUrls: ["./warehouse-cargo-entry.component.css"],
})
export class WarehouseCargoEntryComponent implements OnInit {
  displayedColumns: string[] = [
    "pieces",
    "package",
    "weight",
    "volume",
    "invoice_number",
    "quantity",
    "serial_number",
  ];
  dataSource = [];
  arrayy = [];
  @ViewChild(MatTable, { static: true }) table: MatTable<any>;
  // cargoItemsForm: any = [];
  faCaretDown = faCaretDown;
  faCheck = faCheck;
  faEye = faEye;
  faPlus = faPlus;
  faEdit = faEdit;
  faTimes = faTimes;
  faSort = faSort;
  faCaretUp = faCaretUp;
  faHome = faHome;
  faCaretRight = faCaretRight;
  cargoItemsForm: FormGroup;
  cargoDetailsForm: FormGroup;
  subAccountList: any = [];
  showCargoItemModal: any = false;
  showItemSaveBtn: any = true;
  showDataSaveBtn: any = "";
  showDataUpdateBtn: any = "";
  DetailsHidden: Boolean = true;
  detailError: Boolean = false;
  ItemsHidden: Boolean = false;
  showItemUpdateBtn: any = false;
  submitted = false;
  submitted1 = false;
  previous: string;
  elementError: boolean = false;
  indexValue: any = "";
  conatctArray: any = [];
  contactList: any[];
  customer_subaccount: any = [];
  cargoItems: any = {
    pcs: "",
    package: "",
    dimension: "",
    description: "",
    location: "",
    weight: "",
    invoice_number: "",
    volume: "",
    volume_weight: "",
    notes: "",
    quantity: "",
    po_number: "",
    part_number: "",
    model: "",
    serial_number: "",
    pounds: "",
    height: "",
    cubic_feet: "",
    charge_weight: "",
    reference: "",
    width: "",
    length: "",
  };
  cargoDetails: any = {
    shipper_info: "",
    consignee_info: "",
    carrier_name: "",
    driver_license: "",
    pro_number: "",
    supplier_name: "",
    tracking_number: "",
    invoice_number: "",
    driver_name: "",
    po_number: "",
    notes: "",
    applicable_charges: "",
    details_type: "",
    description: "",
    branch: "",
    reference: "",
    delivered_by: "",
    status: "",
    date: "",
    eta: "",
    etd: "",
    method: "",
    subcustomer: "",
  };
  headElements = [
    "id",
    "pieces",
    "length",
    "height",
    "width",
    "volume",
    "charge weight",
  ];
  // @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  elements: any = [];
  constructor(
    private formBuilder: FormBuilder,
    private _router: Router,
    private cdRef: ChangeDetectorRef,
    private http: HttpProvider,
    private httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private component: SidenavComponent
  ) {
    this.getdata();
  }
  ngOnInit(): void {
    let str = window.location.href;
    let myArr = str.split("/cargoentry/");
    let cargoUrl = myArr[1].split("/");
    let cargoId = cargoUrl[1];

    this.cargoDetails.details_type = myArr[1];
    // console.log("type", cargoUrl[0]);
    if (cargoId) {
      GlobalConstants.cargoEntrySavebtn = false;
      GlobalConstants.cargoEntryUpdatebtn = true;
      this.showDataSaveBtn = GlobalConstants.cargoEntrySavebtn;
      this.showDataUpdateBtn = GlobalConstants.cargoEntryUpdatebtn;
      this.EditGetData(cargoId);
     
    } else {
      console.log("withoutid");
      this.DetailsHidden = false;
      this.showDataSaveBtn = GlobalConstants.cargoEntrySavebtn;
      this.showDataUpdateBtn = GlobalConstants.cargoEntryUpdatebtn;
    }
    this.cargoItemsForm = this.formBuilder.group({
      pcs: ["", Validators.required],
      package: [""],
      dimension: [""],
      description: [""],
      location: [""],
      weight: [""],
      invoice_number: [""],
      volume: ["", Validators.required],
      volume_weight: ["", Validators.required],
      notes: [""],
      quantity: [""],
      po_number: [""],
      part_number: [""],
      model: [""],
      serial_number: [""],
      pounds: [""],
      height: ["", Validators.required],
      cubic_feet: ["", Validators.required],
      charge_weight: ["", Validators.required],
      reference: [""],
      width: ["", Validators.required],
      length: ["", Validators.required],
    });
    this.cargoDetailsForm = this.formBuilder.group({
      shipper_info: ["", Validators.required],
      consignee_info: ["", Validators.required],
      carrier_name: [""],
      driver_license: [""],
      pro_number: [""],
      supplier_name: [""],
      tracking_number: ["", Validators.required],
      invoice_number: [""],
      driver_name: [""],
      po_number: [""],
      notes: [""],
      applicable_charges: [""],
      description: [""],
      branch: [""],
      reference: [""],
      delivered_by: [""],
      date: ["", Validators.required],
      status: [""],
      eta: [""],
      etd: [""],
      subcustomer: [""],
    });
  }
  homeLink() {
    this.component.ngOnInit();
    if (localStorage.getItem("Role") == "Admin") {
      this._router.navigateByUrl("/adminDashboard");
    } else {
      this._router.navigateByUrl("/backofficeDashboard");
    }
  }
  //open cargo item entry modal
  openCargoItemModal() {
    this.onReset();
    this.showCargoItemModal = true;
  }
  //close cargo item entry modal
  closeCargoItemModal() {
    this.showCargoItemModal = false;
  }
  get f() {
    return this.cargoItemsForm.controls;
  }
  get g() {
    return this.cargoDetailsForm.controls;
  }

  //save cargo items entry
  saveCargoItems() {
    // console.log("beforepush", GlobalConstants.cargoItems);
    // console.log(this.elements);
    // console.log("onsubmit", this.cargoItems);
    this.submitted = true;
    // stop here if form is invalid
    if (this.cargoItemsForm.invalid) {
      return;
    }
    const data = {
      ...this.cargoItems,
    };
    this.elements.push(data);
    if (this.elements.length == 0) {
      this.elementError = true;
    } else {
      this.elementError = false;
    }
    this.showCargoItemModal = false;
  }
  //Reset modal form values
  onReset() {
    // console.log(this.elements)
    this.submitted = false;
    this.cargoItemsForm.reset();
    this.showItemSaveBtn = true;
    this.showItemUpdateBtn = false;
  }
  async EditGetData(e) {
    this.ngxService.start();
    await this.getdata();
    // console.log(e);
    this.httpClient
      .get(this.http.BASE_URI + "editCargoEntry/" + e)
      .subscribe((response: any) => {
        // console.log("Response", response);
        this.cargoDetails.shipper_info =
          response.cargo_entry.shipper_information;
        this.cargoDetails.consignee_info = response.cargo_entry.consignee_name;
        this.cargoDetails.carrier_name = response.cargo_entry.carrier_name;
        this.cargoDetails.driver_license = response.cargo_entry.driver_license;
        this.cargoDetails.pro_number = response.cargo_entry.pro_number;
        this.cargoDetails.supplier_name = response.cargo_entry.supplier_name;
        this.cargoDetails.tracking_number =
          response.cargo_entry.tracking_number;
        this.cargoDetails.invoice_number = response.cargo_entry.invoice_number;
        this.cargoDetails.driver_name = response.cargo_entry.driver_name;
        this.cargoDetails.po_number = response.cargo_entry.po_number;
        this.cargoDetails.notes = response.cargo_entry.notes;
        this.cargoDetails.applicable_charges =
          response.cargo_entry.applicable_charges;
        this.cargoDetails.details_type = response.cargo_entry.cargo_type;
        this.cargoDetails.description = response.cargo_entry.description;
        this.cargoDetails.branch = response.cargo_entry.branch;
        this.cargoDetails.reference = response.cargo_entry.reference;
        this.cargoDetails.delivered_by = response.cargo_entry.delivered_by;
        this.cargoDetails.date = response.cargo_entry.date;
        this.cargoDetails.method = response.cargo_entry.method;
        this.cargoDetails.eta = response.cargo_entry.eta_date;
        this.cargoDetails.etd = response.cargo_entry.etd_date;
        this.cargoDetails.status =
          response.cargo_entry.cargo_status == null
            ? ""
            : response.cargo_entry.cargo_status;
        this.cargoDetails.subcustomer = response.cargo_entry.sub_account_id;
        this.elements =  response.cargo_items;
        
        this.ngxService.stop();
      });
      await this.DetailHidden('up');
  }

  updateCargodata() {
    this.submitted1 = true;
    console.log(this.cargoDetailsForm);
    if (this.cargoDetailsForm.invalid) {
      this.detailError = true;
      return;
    }
    if (this.elements.length == 0) {
      this.elementError = true;
      return;
    }
    this.sendData();
  }
  onEditItem(e, i) {
    // console.log(i);
    // this.onReset();
    this.showItemSaveBtn = false;
    this.showItemUpdateBtn = true;
    this.showCargoItemModal = true;
    this.cargoItems.pcs = e.pcs;
    this.cargoItems.package = e.package;
    this.cargoItems.dimension = e.dimension;
    this.cargoItems.description = e.description;
    this.cargoItems.location = e.location;
    this.cargoItems.weight = e.weight;
    this.cargoItems.invoice_number = e.invoice_number;
    this.cargoItems.volume = e.volume;
    this.cargoItems.volume_weight = e.volume_weight;
    this.cargoItems.notes = e.notes;
    this.cargoItems.quantity = e.quantity;
    this.cargoItems.po_number = e.po_number;
    this.cargoItems.part_number = e.part_number;
    this.cargoItems.model = e.model;
    this.cargoItems.serial_number = e.serial_number;
    this.cargoItems.pounds = e.pounds;
    this.cargoItems.height = e.height;
    this.cargoItems.cubic_feet = e.cubic_feet;
    this.cargoItems.charge_weight = e.charge_weight;
    this.cargoItems.reference = e.reference;
    this.cargoItems.width = e.width;
    this.cargoItems.length = e.length;
    this.cargoDetails.subcustomer = e.sub_account_name;
    // pounds:"",
    // height:"",
    // cubic_feet:"",
    // charge_weight:"",
    // reference:""
    this.indexValue = i;
  }
  updateCargoItems() {
    this.elements[this.indexValue] = this.cargoItems;
    this.showCargoItemModal = false;
  }
  deleteCargoItems(e, i) {
    this.indexValue = i;
    Swal.fire({
      title: "Are you sure you want to this record?",
      text: "",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        this.elements.splice(this.indexValue, 1);
        Swal.fire("", "Cargo item deleted successfully", "success");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  }
  Reset() {
    this.cargoItems.pcs = "";
    this.cargoItems.package = "";
    this.cargoItems.dimension = "";
    this.cargoItems.description = "";
    this.cargoItems.location = "";
    this.cargoItems.weight = "";
    this.cargoItems.invoice_number = "";
    this.cargoItems.volume = "";
    this.cargoItems.volume_weight = "";
    this.cargoItems.notes = "";
    this.cargoItems.quantity = "";
    this.cargoItems.po_number = "";
    this.cargoItems.part_number = "";
    this.cargoItems.model = "";
    this.cargoItems.serial_number = "";
  }
  saveCargodata() {
    // console.log(this.elements.length)
    this.submitted1 = true;
    if (this.cargoDetailsForm.invalid) {
      this.detailError = true;
      return;
    }
    if (this.elements.length == 0) {
      this.elementError = true;
      return;
    }
    this.sendData();
  }
  onDataReset() {
    this.submitted1 = false;
    GlobalConstants.cragoId = "";
    if (this.cargoDetails.details_type == "air") {
      this._router.navigateByUrl("warehouseAircargo");
    } else {
      this._router.navigateByUrl("warehousOceancargoe");
    }
  }
  async DetailHidden(e) {
    if (e == "up") {
      this.DetailsHidden = false;
    } else {
      this.DetailsHidden = true;
    }
  }
  ItemHidden(e) {
    if (e == "up") {
      this.ItemsHidden = false;
    } else {
      this.ItemsHidden = true;
    }
  }
  getdata() {
    this.ngxService.start();
    let Array = [];
    this.httpClient
      .get(this.http.BASE_URI + "listCustomers")
      .subscribe((response: any) => {
        // console.log("Response", response.data.customers.active);
        this.conatctArray = response.data.customers.active;
        response.data.customers.active.forEach((element) => {
          if(element.last_name != null){
              Array.push(element.first_name + " " + element.last_name);
          }else{
              Array.push(element.first_name);
          }
        });

        this.contactList = Array;
        this.ngxService.stop();
      });
  }
  getSubcustomer(e) {
    if (e == "") {
      return;
    }
    this.ngxService.start();
     let newContactArr = [];
   
    this.conatctArray.forEach((element)=>{
       if(element.last_name != null){
              newContactArr.push({fullName:element.first_name + " " + element.last_name,id:element.id});
          }else{
              newContactArr.push({fullName:element.first_name,id:element.id});
          }
    })

    let obj = newContactArr.find(
      (o) =>
        (o.fullName) === e
    );

    this.httpClient
      .get(this.http.BASE_URI + "listSubCustomers/" + obj.id)
      .subscribe((response: any) => {
        // console.log("Response", response)
        this.customer_subaccount = response;
        this.ngxService.stop();
      });
  }
  sendData() {
    // console.log(this.elements.reduce((n, {cubic_feet}) => n + parseInt(cubic_feet), 0))
    
    this.ngxService.start();
    let newContactArr = [];
    this.conatctArray.forEach((element)=>{
       if(element.last_name != null){
              newContactArr.push({fullName:element.first_name + " " + element.last_name,id:element.id});
          }else{
              newContactArr.push({fullName:element.first_name,id:element.id});
          }
    })
    
    let obj = newContactArr.find(
      (o) =>
        (o.fullName) === this.cargoDetails.consignee_info
    );
    
    let request = {
      cargo_entry: {
        created_by: localStorage.getItem("Id"),
        shipper_information: this.cargoDetails.shipper_info,
        consignee_information: obj.id,
        carrier_name: this.cargoDetails.carrier_name,
        sub_account_id: this.cargoDetails.subcustomer,
        driver_license: this.cargoDetails.driver_license,
        supplier_name: this.cargoDetails.supplier_name,
        driver_name: this.cargoDetails.driver_name,
        notes: this.cargoDetails.notes,
        applicable_charges: this.cargoDetails.applicable_charges,
        invoice_number: this.cargoDetails.invoice_number,
        pro_number: this.cargoDetails.pro_number,
        tracking_number: this.cargoDetails.tracking_number,
        po_number: this.cargoDetails.po_number,
        cargo_type: this.cargoDetails.details_type,
        gross_weight: this.elements.reduce(
          (n, { pounds }) => n + parseInt(pounds),
          0
        ),
        volume: this.elements.reduce(
          (n, { cubic_feet }) => n + parseInt(cubic_feet),
          0
        ),
        charge_weight: this.elements.reduce(
          (n, { charge_weight }) => n + parseInt(charge_weight),
          0
        ),
        branch: this.cargoDetails.branch,
        reference: this.cargoDetails.reference,
        delivered_by: this.cargoDetails.delivered_by,
        description: this.cargoDetails.description,
        pcs: this.elements.reduce((n, { pcs }) => n + parseInt(pcs), 0),
        method:
          this.cargoDetails.method == "" ? "manual" : this.cargoDetails.method,
        eta_date: this.cargoDetails.eta,
        etd_date: this.cargoDetails.etd,
        cargo_status: this.cargoDetails.status
          ? this.cargoDetails.status
          : "Pending",
        date: this.cargoDetails.date,
      },
      cargo_items: this.elements,
    };
     console.log(request);

    if (GlobalConstants.cragoId == "") {
      this.httpClient
        .post(this.http.BASE_URI + "addCargoEntry", request)
        .subscribe((response: any) => {
          // console.log("Response", response);
        Swal.fire("", "Cargo Created successfully", "success");
          this.onDataReset();
        });
    } else {
      this.httpClient
        .post(
          this.http.BASE_URI + "updateCargoEntry/" + GlobalConstants.cragoId,
          request
        )
        .subscribe((response: any) => {
          // console.log("Response", response);
        Swal.fire("", "Cargo Updated successfully", "success");
          this.onDataReset();
        });
    }
  }
  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if (charCode < 46 || charCode ==47 || charCode > 57) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
}
