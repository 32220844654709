import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import {
  faSort,
  faPlus,
  faEdit,
  faTimes,
  faCaretRight,
  faHome,
  faCheck,
  faMinus,
  faTimesCircle,
  faCheckCircle
} from "@fortawesome/free-solid-svg-icons";
import {
  MdbTableDirective,
  MdbTablePaginationComponent,
} from "angular-bootstrap-md";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { getMaxListeners } from "process";
import { HttpClient } from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { HttpProvider } from "../../common/http/http.provider";
import { HttpHeaders } from "@angular/common/http";
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { SidenavComponent } from "../sidenav/sidenav.component";
import { Router } from "@angular/router";
import * as _ from "lodash";
import { GlobalConstants } from "../../common/helpers/global_variables";
declare var $: any;
@Component({
  selector: "app-superadmin",
  templateUrl: "./superadmin.component.html",
  styleUrls: ["./superadmin.component.css"],
})
export class SuperadminComponent implements OnInit {
  constructor(
    private modalService: NgbModal,
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    private http: HttpProvider,
    private httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private component: SidenavComponent,
    private _router: Router
  ) {}
  deleteoption:any='';
  surinameFields : any = true;
  showModel: any = false;
  submitButton: any = true;
  updateButton: any = false;
  registerForm: FormGroup;
  submitted = false;
  stateshow: boolean = false;
  citieseshow: boolean = false;
  indexvalue: any = "";
  roles: any = [];
  countries: any = "";
  states: any = "";
  cities: any = "";
  countryvalues: any = "";
  cityvalues: any = "";
  statevalues: any = "";
  check: any = true;
  intials:any = '';
  customer: any = {
    id: "",
    first_name: "",
    last_name: "",
    name: "",
    email: "",
    mobile: "",
    address: "",
    roles: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
    //  password:"",
    useractive: "",
  };
  request: any = "";
  closeResult: string;
  faSort = faSort;
  faPlus = faPlus;
  faEdit = faEdit;
  faTimes = faTimes;
  faHome = faHome;
  faCaretRight = faCaretRight;
  faCheck = faCheck;
  faMinus = faMinus;
  faTimesCircle = faTimesCircle;
  faCheckCircle = faCheckCircle;
  profilePicture:any="";
  useractive:boolean = false;
  isProfiled:any="";
  isIntial:boolean = false;
  isUserAdd = false;
  @ViewChild('chooseInput')
chooseInput: any;
  // @HostListener('input') oninput() {
  //   this.searchItems();
  // }
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  elements: any = [];
  isDesc: boolean = false;
  column: string = "CategoryName";
  page = 1;
   pageSize = 25;
   numberOfRecords : number;
  headElements = ["name", "email", "mobile", "country", "role"];
  searchText: string = "";
  previous: string;
  // indexvalue = 5;

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnInit(): void {
    GlobalConstants.pagename = "User";
    if(localStorage.getItem("Role")=='CustomerSupportSupervisor'||localStorage.getItem("Role")=='Admin'){
      this.deleteoption=true; 
    }
    this.ngxService.start();
    this.getdata();
    this.registerForm = this.formBuilder.group({
      first_name: ["", Validators.required],
      last_name: ["", Validators.required],
      // name: ['', Validators.required],
      email: ["", [Validators.required, Validators.email]],
      mobile: ["", [Validators.required, Validators.minLength(6)]],
      address: ["", Validators.required],
      roles: ["", Validators.required],
      country: ["", Validators.required],
      state: [""],
      city: [""],
      zipcode: ["", Validators.required],
      //  password: ['', [Validators.required,Validators.minLength(8),Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')]],
      useractive: [""],
      
    });
    $(".js-example-placeholder-single").select2({
      placeholder: "Select a state",
      allowClear: true
  });
    // this.elements.push(this.dataEntry);
  }

  homeLink() {
    this.component.ngOnInit();
    if (localStorage.getItem("Role") == "Admin") {
      this._router.navigateByUrl("/adminDashboard");
    } else {
      this._router.navigateByUrl("/backofficeDashboard");
    }
  }
  countrySelect(){
    this.chooseInput.nativeElement.focus()
  }
  // searchItems() {
  //   const prev = this.mdbTable.getDataSource();
  //   if (!this.searchText) {
  //     this.mdbTable.setDataSource(this.previous);
  //     this.elements = this.mdbTable.getDataSource();
  //   }
  //   if (this.searchText) {
  //     this.elements = this.mdbTable.searchLocalDataByMultipleFields(
  //       this.searchText,
  //       ["name", "email", "mobile", "address", "role"]
  //     );
  //     this.mdbTable.setDataSource(prev);
  //   }
  // }
  onRowCreate(e, i) {
    this.isUserAdd = false;
    this.showModel = true;
    this.updateButton = true;
    this.submitButton = false;
    // this.indexvalue = i;
    this.customer.id = e.id;
    this.customer.first_name = e.first_name;
    this.customer.last_name = e.last_name;
    this.customer.name = e.name;
    this.customer.mobile = e.phone;
    this.customer.email = e.email;
    this.customer.address = e.address;
    this.customer.country = e.country;
    this.customer.state = e.state;
    this.customer.city = e.city;
    this.customer.roles = e.role;
    this.customer.zipcode = e.zipcode;
    //this.customer.useractive= e.useractive
    if (e.is_user_active == "yes") {
      // console.log("eeee=>>>" + e.is_user_active);
      this.customer.useractive = true;
    } else {
      this.customer.useractive = false;
    }
   
    if(e.first_name != "" && e.first_name != "NULL" && e.first_name != undefined && e.last_name != "" && e.last_name != "NULL" && e.last_name != undefined){
      var firstName = e.first_name;
      var lastName = e.last_name;
      this.intials = firstName.charAt(0) + lastName.charAt(0);
    }
    
    if (e.image != "" || e.image != "NULL" || e.image != undefined ) {
        if(e.image == null || e.image == 'null'){
           this.isProfiled = '';
           this.isIntial = true;
          //  console.log("image====>"+ e.image);
        }else{
          this.profilePicture = e.image;
          this.isProfiled = true;
          this.isIntial = false;
          // console.log('ssssss----'+ this.profilePicture)
        }
    }
  }
  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57) && (charCode < 40 || charCode > 45)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  onRowRemove(e, i) {
    this.indexvalue = i;
    Swal.fire({
      title: "Are you sure want to delete this user?",
      // text: 'You will not be able to recover this data!',
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.ngxService.start();
        this.httpClient
          .post(this.http.BASE_URI + "deactivateUser/" + e.id, "")
          .subscribe((response: any) => {
            // console.log("Response", response);
            this.check = !this.check;
            this.getdata();
            this.onReset();
            this.cancel();
            this.ngxService.stop();
          });
        Swal.fire("Deleted!", "Your data has been deleted.", "success");
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire(
        //   'Cancelled',
        //   'Your data is safe :)',
        //   'error'
        // )
      }
    });
  }
  get f() {
    return this.registerForm.controls;
  }
  onSubmitClick() {
    // console.log("onsubmit", true);
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.onSend("insert");
  }
  onUpdateClick() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.onSend("update");
  }
  onReset() {
    this.submitted = false;
    this.registerForm.reset();
    this.profilePicture = "";
    this.isProfiled = "";
    this.updateButton = false;
    this.submitButton = true;
  }
  aftersubmit() {
    // $('.modal').modal('hide');
    // $("#myModal").modal("hide");
    // Swal.fire('Thank you...', 'You submitted succesfully!', 'success')
  }
  configFormModel() {
    this.onReset();
    this.showModel = true;
    this.useractive = !this.useractive;
    this.intials = '';
    this.isIntial = false;
    this.isUserAdd = true;

    // this.insertbutton = true;
  }
  cancel() {
    this.showModel = false;
    this.useractive = false;
  }
  getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }
  countryselect(e) {
    // console.log("contry===>", e == '')
    if (e == null || e == "") {
      return;
    }

    if(e == 'Suriname'){
      this.surinameFields = true;
      console.log("\n country value===>", e)
    }else{
      this.surinameFields = false;
    }

    // console.log();
    // console.log("\n country value===>", e)
    this.ngxService.start();
    this.httpClient
      .get(
        this.http.BASE_URI +
          "statelist/" +
          this.getKeyByValue(this.countries, e)
      )
      .subscribe((response: any) => {
        // console.log("Response", response)
        this.states = response;
        this.stateshow = true;
        this.ngxService.stop();
      });
      this.ngxService.stop();
  }
  stateselect(e) {
    if (e == null || e == "") {
      return;
    }
    // console.log("\n country value===>", this.customer.country)
    this.ngxService.start();
    this.httpClient
      .get(
        this.http.BASE_URI +
          "citylist/" +
          this.getKeyByValue(this.countries, this.customer.country)
      )
      .subscribe((response: any) => {
        // console.log("Response", response)
        this.cities = response;
        this.citieseshow = true;
        this.ngxService.stop();
      });
  }
  onSelection(e) {
    // console.log('eventTriggerd\n')
    // console.log("ModelChange=====>", e)
  }

  getdata() {
    if (this.check == true) {
      this.ngxService.start();
      this.httpClient
        .get(this.http.BASE_URI + "listusers")
        .subscribe((response: any) => {
          this.elements = response.data.users.active;
          let d = [];
          response.data.role.forEach((element) => {
            d.push(element.role);
          });
          this.roles = d;
          // console.log(this.roles)
          this.countries = response.data.countries;
          this.countryvalues = Object.values(response.data.countries);
          // this.mdbTable.setDataSource(this.elements);
          // this.previous = this.mdbTable.getDataSource();
          // this.cdRef.detectChanges();
          this.ngxService.stop();
          this.check = false;
          this.loadPage(this.page);
        });

    } else {
      this.ngxService.start();
      this.httpClient
        .get(this.http.BASE_URI + "listusers")
        .subscribe((response: any) => {
          this.elements = response.data.users.deactive;
          let d = [];
          response.data.role.forEach((element) => {
            d.push(element.role);
          });
          this.roles = d;
          // console.log(this.roles)
          this.countries = response.data.countries;
          this.countryvalues = Object.values(response.data.countries);
          // this.mdbTable.setDataSource(this.elements);
          // this.previous = this.mdbTable.getDataSource();
          // this.cdRef.detectChanges();
          this.ngxService.stop();
          this.check = true;
          this.loadPage(this.page);
        });
    }
    // this.httpClient
    //   .get(this.http.BASE_URI + "listusers")
    //   .subscribe((response: any) => {
    //     // console.log("Response", response)
    //     this.elements = response.data.users.active;
    //     this.loadPage(this.page);
    //     // this.mdbTable.setDataSource(this.elements);
    //     // this.previous = this.mdbTable.getDataSource();
    //     // this.cdRef.detectChanges();
    //     let d = [];
    //     response.data.role.forEach((element) => {
    //       d.push(element.role);
    //     });
    //     this.roles = d;
    //     // console.log(this.roles)
    //     this.countries = response.data.countries;
    //     this.countryvalues = Object.values(response.data.countries);
    //     this.ngxService.stop();
    //   });
  }

  checkValue() {
    // console.log(this.check);
    this.getdata();
  }
  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ["image/png", "image/jpeg"];
      const max_height = 15200;
      const max_width = 25600;

      if (fileInput.target.files[0].size > max_size) {
        Swal.fire(
          "Oops...",
          "Maximum size allowed is " + max_size / 1000 + "Mb",
          "error"
        );
        return false;
      }

      if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
        Swal.fire("Oops...", "Only Images are allowed ( JPG | PNG )", "error");
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          const img_height = rs.currentTarget["height"];
          const img_width = rs.currentTarget["width"];

          console.log(img_height, img_width);

          if (img_height > max_height && img_width > max_width) {
            Swal.fire(
              "Oops...",
              "Maximum dimentions allowed " +
                max_height +
                "*" +
                max_width +
                "px",
              "error"
            );
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.profilePicture = e.target.result;
            this.isProfiled = true;
            this.isIntial = false;
            this.isUserAdd = false;

            // localStorage.setItem("ProfileImg", imgBase64Path);
            // this.ngxService.start();
            // let request = {
            //   image: imgBase64Path,
            // };
            // this.httpClient
            //   .post(this.http.BASE_URI + "uploadProfile/"+localStorage.getItem('Id'), request)
            //   .subscribe((response: any) => {
            //     // console.log("Response", response);
            //     this.ngOnInit();
            //     this.ngxService.stop();

            //   });
            // this.previewImagePath = imgBase64Path;
          }

          // this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(this.cardImageBase64);
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }
  activeUser(e) {
    // console.log("testing", e);
  }
  onSend(e) {
  // console.log(this);
    this.ngxService.start();
    let request = {
      id: this.customer.id,
      first_name: this.customer.first_name,
      last_name: this.customer.last_name,
      name: this.customer.first_name + " " + this.customer.last_name,
      email: this.customer.email,
      role: this.customer.roles,
      phone: this.customer.mobile,
      address: this.customer.address,
      city: this.customer.city,
      state: this.customer.state,
      zipcode: this.customer.zipcode,
      country: this.customer.country,
      image: this.profilePicture,
      is_user_active: this.customer.useractive == true || this.customer.useractive == null ? "yes" : "no",
    };
   // console.log("this.customer.useractive=>>>>>>>",this.customer.useractive)
    // console.log(request);return;
    let header = new HttpHeaders();
    header.set("Access-Control-Allow-Origin", "*");
    this.httpClient
      .post(this.http.BASE_URI + "addUpdateUser", request)
      .subscribe((response: any) => {
        // console.log("Response", response);
        var status = response.success;
        if (status == false) {
          //  console.log(response.data.email)
          var errorMessage = response.data;
          var errorMessagevalues = Object.values(errorMessage);
          // console.log(errorMessagevalues);
          var error = "";
          errorMessagevalues.forEach((e) => {
            error += e;
          });
          Swal.fire("", error, "warning");
          this.ngxService.stop();
          return false;
        }
        this.check = !this.check;
        this.getdata();
        if (e == "insert") {
          Swal.fire("", "User created successfully and mail has been sent to your registered email", "success");
        } else {
          Swal.fire("", "User updated succesfully", "success");
        }
        this.onReset();
        this.customer.id = "";
        this.cancel();
      });
  }
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.elements.sort(function (a, b) {
      if (a[property].toLowerCase() < b[property].toLowerCase()) {
        return -1 * direction;
      }
      else if (a[property].toLowerCase() > b[property].toLowerCase()) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  loadPage(e){
    this.numberOfRecords = (e - 1) * this.pageSize + this.pageSize > this.elements.length ? this.elements.length :  (e - 1) * this.pageSize + this.pageSize;
  }
    //make user active and inactive
  userActiveInactive(id,value){
    let request ={
      id,value
    };
    this.httpClient.post(this.http.BASE_URI + 'activeInactiveUser', request)
    .subscribe((response: any) => {
      var status = response.success;
    //  console.log(status)
      if(status){

      }else{

      }
      this.check = !this.check;
      this.getdata();
    });
  }
}
