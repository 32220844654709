import { Component, OnInit, ViewChild } from "@angular/core";
import {
  faHome,
  faCaretRight,
  faSort,
  faCheck,
  faTimes
} from "@fortawesome/free-solid-svg-icons";
import { HttpClient } from "@angular/common/http";
import { HttpProvider } from "../../common/http/http.provider";
import { HttpHeaders } from "@angular/common/http";
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { SidenavComponent } from "../sidenav/sidenav.component";
import { Router } from "@angular/router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  MdbTableDirective,
  MdbTablePaginationComponent,
} from "angular-bootstrap-md";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
@Component({
  selector: 'app-backofficeuser-claim-request',
  templateUrl: './backofficeuser-claim-request.component.html',
  styleUrls: ['./backofficeuser-claim-request.component.css']
})
export class BackofficeuserClaimRequestComponent implements OnInit {
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  claimNotificationsData : any = [];
  searchText: string = "";
  faCaretRight = faCaretRight;
  faHome = faHome;
  faTimes = faTimes;
  faSort = faSort;
  faCheck = faCheck;
  page = 1;
  pageSize = 25;
  numberOfRecords: number;
  isDesc: boolean = false;
  column: string = "CategoryName";

  constructor(
    private component: SidenavComponent,
    private _router: Router,
    private http: HttpProvider,
    private httpClient: HttpClient,
    private formBuilder: FormBuilder,
    private ngxService: NgxUiLoaderService
  ) { }

  ngOnInit(): void {
    this.getClaimNotification()
  }
  homeLink() {
    this.component.ngOnInit();
    if (localStorage.getItem("Role") == "Admin") {
      this._router.navigateByUrl("/adminDashboard");
    } else {
      this._router.navigateByUrl("/backofficeDashboard");
    }
  }
  getClaimNotification(){
    this.httpClient
    .get(this.http.BASE_URI + "getClaimNotifications")
    .subscribe((response: any) => {
      this.claimNotificationsData = [];
      this.claimNotificationsData=response.all;
      //this.numberOfRecords = this.claimNotificationsData.length
       console.log("claimNotificationsData" + this.claimNotificationsData);
    });
  }
  //closed claim request
  closedClaim(id){
    var request = {
      id:id,
      status:"closed"
    }
    this.ngxService.start();
    this.httpClient
    .post(this.http.BASE_URI + "addUpdateClaim", request)
    .subscribe((response: any) => {
       console.log("Response", response);
      this.getClaimNotification();
      this.ngxService.stop();
      Swal.fire("", "Request Closed", "success");
    });
  }
  //delete claim record
  deleteClaim(id){
    Swal.fire({
      title: "Are you sure want to delete this record?",
      text: "",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.ngxService.start();
        this.httpClient
          .delete(this.http.BASE_URI + "deleteClaim/" + id)
          .subscribe((response: any) => {
            // console.log("Response", response);
            this.getClaimNotification();
            this.ngxService.stop();
             Swal.fire("Deleted!", "", "success");
          });
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  }
  sort(property) {
    // console.log(property)
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;
    this.claimNotificationsData.sort(function (a, b) {
      if (a[property].toLowerCase() < b[property].toLowerCase()) {
        return -1 * direction;
      }
      else if (a[property].toLowerCase() > b[property].toLowerCase()) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  sort1(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;
    this.claimNotificationsData.sort(function (a, b) {
    if (parseInt(a[property]) < parseInt(b[property])) {
    return -1 * direction;
    }
    else if (parseInt(a[property]) > parseInt(b[property])) {
    return 1 * direction;
    }
    else {
    return 0;
    }
    });
    };
  //showing pagination count
  loadPage(e){
    this.numberOfRecords = (e - 1) * this.pageSize + this.pageSize > this.claimNotificationsData.length ? this.claimNotificationsData.length :  (e - 1) * this.pageSize + this.pageSize;
  }
}
