import { Component, ViewChild, OnInit, AfterViewInit } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { MaterialModule } from "src/app/common/material.module";
import { BreakpointObserver } from "@angular/cdk/layout";
import { delay } from "rxjs/operators";
import {
  faCog,
  faSearch,
  faBars,
  faTachometerAlt,
  faPlane,
  faShip,
  faDatabase,
  faCheck,
  faCheckDouble,
  faOutdent,
  faFileInvoice,
  faShoppingBag,
  faFile,
  faIdBadge,
  faStickyNote,
  faUser,
  faCaretDown,
  faSignOutAlt,
  faCaretRight,
  faCamera,
  faTimes,
  faChevronLeft,
  faHome,
  faQuestion,
  faUserCog,
  faShareSquare,
  faTruck
} from "@fortawesome/free-solid-svg-icons";
import * as CryptoJS from "crypto-js";

// import { Router } from "@angular/router";
import { GlobalConstants } from "../../common/helpers/global_variables";
import { DomSanitizer } from "@angular/platform-browser";
import Swal from "sweetalert2/dist/sweetalert2.js";
import * as _ from "lodash";
import { HttpClient } from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { HttpProvider } from "../../common/http/http.provider";
import { HttpHeaders } from "@angular/common/http";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { Observable } from "rxjs";
import { filter, map, switchMap } from "rxjs/operators";
import { RouteData } from "./route-data";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";
import { Location } from "@angular/common";
@Component({
  selector: "app-sidenav",
  templateUrl: "./sidenav.component.html",
  styleUrls: ["./sidenav.component.css"],
})
export class SidenavComponent implements OnInit, AfterViewInit {
  //  public pageTitle: string;
  constructor(
    private _router: Router,
    private sanitizer: DomSanitizer,
    private formBuilder: FormBuilder,
    private http: HttpProvider,
    private httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private observer: BreakpointObserver,
    private _location: Location,
    // private router: Router,
    private activatedRoute: ActivatedRoute
  ) {}
  registerForm: FormGroup;
  surinameFields : any = true;
  Roles: any = {
    warehouse: false,
    customersupport: false,
    admin: false,
    backoffice: false,
  };
  user: any = {
    id: "",
    first_name: "",
    last_name: "",
    name: "",
    email: "",
    mobile: "",
    address: "",
    // roles: "",
    country: "",
    state: "",
    city: "",
    zipcode: "",
  };
  countries: any = "";
  states: any = "";
  cities: any = "";
  countryvalues: any = "";
  cityvalues: any = "";
  submitted = false;
  statevalues: any = "";
  stateshow: boolean = false;
  citieseshow: boolean = false;
  ProfileModal: any = false;
  username: any = "";
  role: any = "";
  faCog = faCog;
  faHome = faHome;
  faChevronLeft = faChevronLeft;
  faSearch = faSearch;
  faCaretDown = faCaretDown;
  faFile = faFile;
  faSignOutAlt = faSignOutAlt;
  faIdBadge = faIdBadge;
  faStickyNote = faStickyNote;
  FaBars = faBars;
  FaTachometerAlt = faTachometerAlt;
  FaPlane = faPlane;
  faShip = faShip;
  faDatabase = faDatabase;
  faCheck = faCheck;
  faCheckDouble = faCheckDouble;
  faOutdent = faOutdent;
  faFileInvoice = faFileInvoice;
  faShoppingBag = faShoppingBag;
  faUser = faUser;
  faCaretRight = faCaretRight;
  faCamera = faCamera;
  faTimes = faTimes;
  faQuestion = faQuestion;
  faUserCog = faUserCog;
  faShareSquare = faShareSquare;
  faTruck=faTruck;
  // @ViewChild("sidenav") sidenav: MatSidenav;
  @ViewChild(MatSidenav)
  sidenav!: MatSidenav;
  isExpanded = GlobalConstants.isExpanded;
  showSubmenu: boolean = false;
  showSetingsSubmenu: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = true;
  showSubMenuW: boolean = false;
  showSubMenuB: boolean = false;
  showSubMenuC: boolean = false;
  profilePicture: any = "";
  isProfiled: any = "";
  intials: any = "";
  isIntial: boolean = false;
  pagename = "Home";
  cargsubmenu() {
    this.showSubmenu = !this.showSubmenu;
  }
  cargsettingmenu() {
    this.showSetingsSubmenu = !this.showSetingsSubmenu;
  }
  breadcrumbPageName(e) {
    this.pagename = e;
  }
  mouseenter() {}
  logout() {
    localStorage.clear();
    this._router.navigateByUrl("/login");
  }
  mouseleave() {}
  showProfileModal() {
    this.ProfileModal = true;
  }
  cancel() {
    this.ProfileModal = false;
  }
  get f() {
    return this.registerForm.controls;
  }
  async backClicked() {
    await this._location.back();
    this.pagetitle();
  }

pagetitle() {
    ​​​​​​
    setTimeout(() => {
        ​​​​​​
        let name = window.location.href.split("/").slice(-1).pop();
        if (name == "superAdmin") {
            ​​​​​​
            this.pagename = "Users";
        }​​​​​​
        else if (name == "adminDashboard") {
            ​​​​​​
            this.pagename = "Home";
        }​​​​​​
        else if (name == "warehouseAircargo") {
            ​​​​​​
            this.pagename = "Air Cargo";
        }​​​​​​
        else if (name == "warehousOceancargoe") {
            ​​​​​​
            this.pagename = "Ocean Cargo";
        }​​​​​​
        else if (name == "backofficeInvoiceOrder") {
            
            console.log(this.pagename);
            this.pagename = "Invoice";
        }​​​​​​
        else if (name == "backofficeReport") {
            ​​​​​​
            this.pagename = "Report";
        }​​​​​​
        else if (name == "backofficeCargostatus") {
            ​​​​​​
            this.pagename = "Status";
        }​​​​​​
        else if (name == "backofficeCargonotes") {
            ​​​​​​
            this.pagename = "Notes";
        }​​​​​​
        else if (name == "backofficeShipping") {
            ​​​​​​
            this.pagename = "Shipping Type";
        }​​​​​​
        else if (name == "customersupportCustomer") {
            ​​​​
            this.pagename = "Customers";
        }​​​​​​
        else {
            this.pagename = "Home";
        }​​​​​​
    }​​​​​​, 500);
}​​​​​​

  onUpdateProfile() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }

    this.onSend("update");
  }
  keyPressNumbers(event) {
    var charCode = event.which ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57) && (charCode < 40 || charCode > 45)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  onSend(e) {
    this.ngxService.start();
    let request = {
      id: this.user.id,
      first_name: this.user.first_name,
      last_name: this.user.last_name,
      name: this.user.first_name + " " + this.user.last_name,
      email: this.user.email,
      role: localStorage.getItem("Role"),
      phone: this.user.phone,
      address: this.user.address,
      city: this.user.city,
      state: this.user.state,
      zipcode: this.user.zipcode,
      country: this.user.country,
      image: this.profilePicture,
    };

    let header = new HttpHeaders();
    header.set("Access-Control-Allow-Origin", "*");
    this.httpClient
      .post(this.http.BASE_URI + "addUpdateUser", request)
      .subscribe((response: any) => {
        // console.log("Response", response);
        this.getUserData();
        if (e == "insert") {
          Swal.fire("", "User created succesfully", "success");
        } else {
          this.username = this.user.name;
          Swal.fire("", "User updated succesfully", "success");
        }
        this.ngxService.stop();
        window.location.reload();
        this.onReset();
        this.user.id = "";
        this.cancel();
      });
  }
  onReset() {
    this.submitted = false;
    this.registerForm.reset();
    this.profilePicture = "";
    this.isProfiled = "";
  }
  getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }
  countryselect(e) {
    if (!e && !this.countries) {
       return;
    }
    if(e == 'Suriname'){
      this.surinameFields = true;

    }else{
      this.surinameFields = false;
    }

    this.httpClient
      .get(
        this.http.BASE_URI +
          "statelist/" +
          this.getKeyByValue(this.countries, e)
      )
      .subscribe((response: any) => {
        // console.log("Response", response)
        this.states = response;
        this.stateshow = true;
        this.ngxService.stop();
      });
      this.ngxService.stop();
  }
  stateselect(e) {
    if (e == null || e == "") {
      return;
    }
    // console.log("\n country value===>", this.customer.country)
    // this.ngxService.start();
    // this.httpClient
    //   .get(
    //     this.http.BASE_URI +
    //       "citylist/" +
    //       this.getKeyByValue(this.countries, this.user.country)
    //   )
    //   .subscribe((response: any) => {
    //     // console.log("Response", response)
    //     this.cities = response;
    //     this.citieseshow = true;
    //     this.ngxService.stop();
    //   });
  }
  onSelection(e) {
    // console.log('eventTriggerd\n')
    // console.log("ModelChange=====>", e)
  }
  getUserData() {
    //  this.ngxService.start();
    this.httpClient
      .get(this.http.BASE_URI + "editUser/" + localStorage.getItem("Id"))
      .subscribe((response: any) => {
        this.user = response.data;
       // console.log('user->>>>>>>>',this.user)
        this.username = this.user.name;
        if (
          this.user.first_name != "" &&
          this.user.first_name != "NULL" &&
          this.user.first_name != undefined &&
          this.user.last_name != "" &&
          this.user.last_name != "NULL" &&
          this.user.last_name != undefined
        ) {
          var firstName = this.user.first_name;
          var lastName = this.user.last_name;
          this.intials = firstName.charAt(0) + lastName.charAt(0);
        }
        if (
          this.user.image != "" ||
          this.user.image != "NULL" ||
          this.user.image != undefined
        ) {
          if (this.user.image == null || this.user.image == "null") {
            this.isProfiled = "";
            this.isIntial = true;
           // console.log("image====>"+ this.user.image);
          } else {
            if (this.user.first_name == null || this.user.last_name == null) {
               //console.log('profile not');
              this.isIntial = true;
            } else {
              this.profilePicture = this.user.image;
              this.isProfiled = true;
              this.isIntial = false;
            }
            // console.log('ssssss----'+ this.profilePicture)
          }
        }
        // this.isProfiled=true;
        // console.log("userData" + response.data);
        let header = new HttpHeaders();
    header.set("Access-Control-Allow-Origin", "*");
    this.httpClient
      .get(this.http.BASE_URI + "listusers")
      .subscribe((response: any) => {
        this.countries = response.data.countries;
        this.countryvalues = Object.values(response.data.countries);
        //      console.log(this.countryvalues)
      });
      });
  }
  ngAfterViewInit() {
    this.observer
      .observe(["(max-width: 800px)"])
      .pipe(delay(1))
      .subscribe((res) => {
        if (res.matches) {
          this.sidenav.mode = "over";

          this.sidenav.close();
        } else {
          this.sidenav.mode = "side";

          this.sidenav.open();
        }
      });
  }
  ngOnInit() {
    this.getUserData();
    this.pagetitle();
    this.registerForm = this.formBuilder.group({
      first_name: ["", Validators.required],
      last_name: ["", Validators.required],
      // name: ["", Validators.required],
      email: ["", [Validators.required, Validators.email]],
      mobile: ["", [Validators.required, Validators.minLength(6)]],
      address: ["", Validators.required],
      // roles: ['', Validators.required],
      country: ["", Validators.required],
      state: [""],
      city: [""],
      zipcode: ["", Validators.required],
    });
    //this.pageTitle = this.activatedRoute.snapshot.data["title"];
    //console.log('this.pageTitle ===>\n',this.pageTitle);
    if (
      localStorage.getItem("ProfileImg") == "" ||
      localStorage.getItem("ProfileImg") == null ||
      localStorage.getItem("ProfileImg") == undefined
    ) {
      this.isProfiled = false;
    } else {
      this.isProfiled = true;
      this.profilePicture = this.sanitizer.bypassSecurityTrustResourceUrl(
        localStorage.getItem("ProfileImg")
      );
    }
    this.role = localStorage.getItem("Role");
    // this.username = localStorage.getItem("Name");
    if (this.role == "Admin") {
      this.role = "Admin";
      this.Roles.admin = true;
    } else if (
      this.role == "BackOfficeUser" ||
      this.role == "BackOfficeSupervisor"
    ) {
      this.Roles.backoffice = true;
      this.role = "Back Office User";
    } else if (
      this.role == "WareHouseUser" ||
      this.role == "WareHouseSupervisor"
    ) {
      this.Roles.warehouse = true;
      this.role = "Warehouse User";
    } else if (
      this.role == "CustomerSupportExecutive" ||
      this.role == "CustomerSupportSupervisor"
    ) {
      this.Roles.customersupport = true;
      this.role = "Customer Support Executive";
    }
    // console.log(this.Roles);
  }
  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ["image/png", "image/jpeg"];
      const max_height = 15200;
      const max_width = 25600;

      if (fileInput.target.files[0].size > max_size) {
        Swal.fire(
          "Oops...",
          "Maximum size allowed is " + max_size / 1000 + "Mb",
          "error"
        );
        return false;
      }

      if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
        Swal.fire("Oops...", "Only Images are allowed ( JPG | PNG )", "error");
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          const img_height = rs.currentTarget["height"];
          const img_width = rs.currentTarget["width"];

          // console.log(img_height, img_width);

          if (img_height > max_height && img_width > max_width) {
            Swal.fire(
              "Oops...",
              "Maximum dimentions allowed " +
                max_height +
                "*" +
                max_width +
                "px",
              "error"
            );
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.profilePicture = e.target.result;
            this.isProfiled = true;
            this.isIntial = false;
            // localStorage.setItem("ProfileImg", imgBase64Path);
            // this.ngxService.start();
            // let request = {
            //   image: imgBase64Path,
            // };
            // this.httpClient
            //   .post(
            //     this.http.BASE_URI +
            //       "uploadProfile/" +
            //       localStorage.getItem("Id"),
            //     request
            //   )
            //   .subscribe((response: any) => {
            //     // console.log("Response", response);
            //     this.ngOnInit();
            //     this.ngxService.stop();
            //   });
            // this.previewImagePath = imgBase64Path;
          }

          // this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(this.cardImageBase64);
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }
}
