import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from "@angular/core";
import { LocationStrategy, PathLocationStrategy } from "@angular/common";
import {MaterialModule} from './common/material.module'
import {SharedModule} from './common/shared.module'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { WarehouseDashboardComponent } from './components/warehouse-dashboard/warehouse-dashboard.component';
import { WarehouseDataentryOceancargoComponent } from './components/warehouse-dataentry-oceancargo/warehouse-dataentry-oceancargo.component';
import { WarehouseDataentryAircargoComponent } from './components/warehouse-dataentry-aircargo/warehouse-dataentry-aircargo.component';
import { WarehouseCheckinComponent } from './components/warehouse-checkin/warehouse-checkin.component';
import { WarehouseCheckoutComponent } from './components/warehouse-checkout/warehouse-checkout.component';
import { BackofficeuserDashboardComponent } from './components/backofficeuser-dashboard/backofficeuser-dashboard.component';
import { BackofficeuserInvoiceandorderComponent } from './components/backofficeuser-invoiceandorder/backofficeuser-invoiceandorder.component';
import { BackofficeuserReportingComponent } from './components/backofficeuser-reporting/backofficeuser-reporting.component';
import { BackofficeuserCargostatusComponent } from './components/backofficeuser-cargostatus/backofficeuser-cargostatus.component';
import { BackofficeuserCargonotesComponent } from './components/backofficeuser-cargonotes/backofficeuser-cargonotes.component';
import { BackofficeuserShippingtypeComponent } from './components/backofficeuser-shippingtype/backofficeuser-shippingtype.component';
import { CustomersupportCustomerComponent } from './components/customersupport-customer/customersupport-customer.component';
import { CustomersupportInvoiceComponent } from './components/customersupport-invoice/customersupport-invoice.component';
import { CustomersupportOrdersComponent } from './components/customersupport-orders/customersupport-orders.component';
// import { LoginComponent } from './components/login/login.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { SuperadminComponent } from './components/superadmin/superadmin.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { JwPaginationModule } from 'jw-angular-pagination';
// import { OwlDateTimeModule, OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { OwlDateTimeModule, OwlNativeDateTimeModule } from '@danielmoncada/angular-datetime-picker';
// import { ForgetPasswordComponent } from './components/forget-password/forget-password.component';
// import { MatNativeDateModule } from "@angular/material/core";
// import { MatMomentDateModule } from "@angular/material-moment-adapter";
import { HttpClientModule } from '@angular/common/http';
// import { ForgetPasswordLinkComponent } from './components/forget-password-link/forget-password-link.component'
import { NgxUiLoaderModule } from "ngx-ui-loader";
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { RolesComponent } from './components/roles/roles.component';
//import { NgApexchartsModule } from "ng-apexcharts";

// import { TestComponent } from './components/test/test.component';
import { WarehouseCargoEntryComponent } from './components/warehouse-cargo-entry/warehouse-cargo-entry.component';
import { AdminDashboardComponent } from './components/admin-dashboard/admin-dashboard.component';
import {BreadcrumbComponent} from './components/breadcrumb/breadcrumb.component';
import { ShipmentTimelineComponent } from './components/shipment-timeline/shipment-timeline.component';
import { DataentryLogComponent } from './components/dataentry-log/dataentry-log.component';
import { BackofficeuserClaimRequestComponent } from './components/backofficeuser-claim-request/backofficeuser-claim-request.component';
import { ShipmentMoniterComponent } from './components/shipment-moniter/shipment-moniter.component';
import { CustomerSubAccountComponent } from './components/customer-sub-account/customer-sub-account.component';
@NgModule({
  declarations: [                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                   
    AppComponent,
    SidenavComponent,
    WarehouseDashboardComponent,
    WarehouseDataentryOceancargoComponent,
    WarehouseDataentryAircargoComponent,
    WarehouseCheckinComponent,
    WarehouseCheckoutComponent,
    BackofficeuserDashboardComponent,
    BackofficeuserInvoiceandorderComponent,
    BackofficeuserReportingComponent,
    BackofficeuserCargostatusComponent,
    BackofficeuserCargonotesComponent,
    BackofficeuserShippingtypeComponent,
    CustomersupportCustomerComponent,
    CustomersupportInvoiceComponent,
    CustomersupportOrdersComponent,
    SuperadminComponent,
    RolesComponent,
    WarehouseCargoEntryComponent,
    AdminDashboardComponent,
    BreadcrumbComponent,
    ShipmentTimelineComponent,
    DataentryLogComponent,
    BackofficeuserClaimRequestComponent,
    ShipmentMoniterComponent,
    CustomerSubAccountComponent,
    // TestComponent,
    // ForgetPasswordLinkComponent,
    // ForgetPasswordComponent,
    // LoginComponent
  ],
  imports: [
    HttpClientModule,
    BrowserModule,
    NgbModule,
    AppRoutingModule,
    MaterialModule,
    BrowserAnimationsModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    JwPaginationModule,
    OwlDateTimeModule, OwlNativeDateTimeModule,
    // MatMomentDateModule,
    MDBBootstrapModule.forRoot(),
    NgxUiLoaderModule,
    Ng2SearchPipeModule,
   // NgApexchartsModule
  ],
  providers: [
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    // MatNativeDateModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent]
})
export class AppModule { }
