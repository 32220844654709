import { Component, OnInit } from '@angular/core';
import {  faHome,  faCaretRight } from "@fortawesome/free-solid-svg-icons";
import {​​​​​​SidenavComponent}​​​​​​ from "../sidenav/sidenav.component";
import {​​​​​​ Router }​​​​​​ from "@angular/router";
@Component({
  selector: 'app-customersupport-orders',
  templateUrl: './customersupport-orders.component.html',
  styleUrls: ['./customersupport-orders.component.css']
})
export class CustomersupportOrdersComponent implements OnInit {
  faHome = faHome;
  faCaretRight = faCaretRight;
  constructor(private component:SidenavComponent,private _router: Router) { }

  ngOnInit(): void {
  }
homeLink(){
this.component.ngOnInit();
if(localStorage.getItem('Role')=='Admin'){
this._router.navigateByUrl("/adminDashboard");
}else{
this._router.navigateByUrl("/backofficeDashboard");
}
}
}
