import { Component, OnInit, ViewChild } from "@angular/core";
import {
  faHome,
  faCaretRight,
  faSort,
} from "@fortawesome/free-solid-svg-icons";
import { HttpClient } from "@angular/common/http";
import { HttpProvider } from "../../common/http/http.provider";
import { HttpHeaders } from "@angular/common/http";
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { SidenavComponent } from "../sidenav/sidenav.component";
import { Router } from "@angular/router";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {
  MdbTableDirective,
  MdbTablePaginationComponent,
} from "angular-bootstrap-md";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

@Component({
  selector: "app-backofficeuser-cargonotes",
  templateUrl: "./backofficeuser-cargonotes.component.html",
  styleUrls: ["./backofficeuser-cargonotes.component.css"],
})
export class BackofficeuserCargonotesComponent implements OnInit {
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  cargoData: any = [];
  registerForm: FormGroup;
  notesModal: boolean = false;
  submitted = false;
  cargo: any = {
    id: "",
    notes: "",
  };
  request: any = "";
  page = 1;
  pageSize = 25;
  totalRecords: number;
  numberOfRecords : number;
  elements: any = [];
  isDesc: boolean = false;
  column: string = "CategoryName";
  headElements = ["shipper information", "consignee information", "Notes"];
  searchText: string = "";
  previous: string;
  faHome = faHome;
  faCaretRight = faCaretRight;
  faSort = faSort;
  constructor(
    private component: SidenavComponent,
    private _router: Router,
    private http: HttpProvider,
    private httpClient: HttpClient,
    private formBuilder: FormBuilder,
    private ngxService: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.getcargoEntryNotes();
    this.registerForm = this.formBuilder.group({
      notes: ["", Validators.required],
    });
  }
  get f() {
    return this.registerForm.controls;
  }

  //getting cargo data's
  getcargoEntryNotes() {
    this.httpClient
    .get(this.http.BASE_URI + "status/cargoEntryStatus?page=" + this.page)
    .subscribe((response: any) => {
      this.cargoData = [];
      this.totalRecords = response.totalCount;
      response.data.forEach(element => {
        this.cargoData.push(element)
      });
      this.ngxService.stop();
    });
  }
  searchItems() {
    const prev = this.mdbTable.getDataSource();
    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.cargoData = this.mdbTable.getDataSource();
    }
    if (this.searchText) {
      this.cargoData = this.mdbTable.searchLocalDataByMultipleFields(
        this.searchText,
        ["consignee_name", "notes"]
      );
      this.mdbTable.setDataSource(prev);
    }
  }
  //update notes
  updateNotes() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    let request = {
      notes: this.cargo.notes,
      created_by:localStorage.getItem("Id")
    };

    // console.log("cargo id"+this.customer.id);
    // console.log("this.cargostatus"+this.customer.name);return;
    let header = new HttpHeaders();
    this.ngxService.start();
    header.set("Access-Control-Allow-Origin", "*");
    this.httpClient
      .post(this.http.BASE_URI + "notes/update/" + this.cargo.id, request)
      .subscribe((response: any) => {
        // console.log("Response", response);
        var status = response.success;
        if (status) {
          this.ngxService.stop();
          Swal.fire("", response.data, "success");
        }
        this.getcargoEntryNotes();
        this.cargo.id = "";
        this.closeNotesModal();
      });
  }
  //open notes modal
  openNotesModal(e, i) {
    this.notesModal = true;
    this.cargo.id = e.id;
    this.cargo.notes = e.notes;
  }
  //close notes modal
  closeNotesModal() {
    this.notesModal = false;
  }
  homeLink() {
    this.component.ngOnInit();
    if (localStorage.getItem("Role") == "Admin") {
      this._router.navigateByUrl("/adminDashboard");
    } else {
      this._router.navigateByUrl("/backofficeDashboard");
    }
  }
  sort(property) {
    // console.log(property)
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;
    this.cargoData.sort(function (a, b) {
      if (a[property].toLowerCase() < b[property].toLowerCase()) {
        return -1 * direction;
      }
      else if (a[property].toLowerCase() > b[property].toLowerCase()) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  //showing pagination count
  loadPage(e){
    this.numberOfRecords = (e - 1) * this.pageSize + this.pageSize > this.cargoData.length ? this.cargoData.length :  (e - 1) * this.pageSize + this.pageSize;
    this.page = e;
    this.getcargoEntryNotes();
  }
}
