import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import {
  faSort,
  faPlus,
  faEdit,
  faTimes,
  faHome,
  faCaretRight,
  faSyncAlt,
  faLink
} from "@fortawesome/free-solid-svg-icons";

import Swal from "sweetalert2/dist/sweetalert2.js";
import { HttpClient } from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { HttpProvider } from "../../common/http/http.provider";
import { HttpHeaders } from "@angular/common/http";
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { SidenavComponent } from "../sidenav/sidenav.component";
import { Router } from "@angular/router";
import { ActivatedRoute, Params } from '@angular/router';

declare var $: any;
@Component({
  selector: "app-backofficeuser-invoiceandorder",
  templateUrl: "./backofficeuser-invoiceandorder.component.html",
  styleUrls: ["./backofficeuser-invoiceandorder.component.css"],
})
export class BackofficeuserInvoiceandorderComponent implements OnInit {
  faSort = faSort;
  faHome = faHome;
  faCaretRight = faCaretRight;
  faSyncAlt = faSyncAlt;
  faLink = faLink;
  spinner: boolean = false;
  params: Params;

  page = 1;
  pageSize = 10;
  numberOfRecords = 10;
  headElements = [
    "invoice id",
    "customer name",
    "invoice date",
    "due date",
    "amount",
  ];
  connectOption:boolean=false;
  searchText: string = "";
  previous: string;
  isDesc: boolean = false;
  column: string = "CategoryName";
  elements: any = [];
  connectionUrl : string = '';
  tokenSet : any = {
    code : '',
    realmId : '',
    redirect_uri : ''
  };
  constructor(
    private http: HttpProvider,
    private httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private component: SidenavComponent,
    private _router: Router,
    private route: ActivatedRoute,
  ) {}

  ngOnInit(): void {
    this.ngxService.start();
    this.getdata();
    this.configQbo();
    this.route.queryParams.subscribe((params: Params) => {
      this.params = params;
       let access_denied = params['error'];
       if(access_denied == 'access_denied'){
          Swal.fire("", "Access Denied", "warning");
          window.close();
          window.opener.location.reload();
          return false;
       }
       if(params['code']){
       this.tokenSet.code = params['code'];
       this.tokenSet.realmId = params['realmId'];
       this.tokenSet.redirect_uri  = window.location.href.split('?')[0]
        this.httpClient
      .post(this.http.BASE_URI + "qbo/config/getToken",this.tokenSet)
      .subscribe((response1: any) => {
      //  console.log('connect Response'+response1)
        var status = response1.success;
        if(status){
          window.close();
         // this.configQbo();
          window.opener.location.reload();
          Swal.fire("", "Qbo Connected Successfully", "success");
        }
      });
       }
    });
  }
  homeLink() {
    this.component.ngOnInit();
    if (localStorage.getItem("Role") == "Admin") {
      this._router.navigateByUrl("/adminDashboard");
    } else {
      this._router.navigateByUrl("/backofficeDashboard");
    }
  }
  getdata() {
    this.httpClient
      .get(this.http.BASE_URI + "cargo/fetchInvoice")
      .subscribe((response: any) => {
        // console.log("Response", response)
        this.elements = Object.values(response.message);
        // console.log(Object.values(this.elements));
        this.ngxService.stop();
      });
  }
  configQbo(){
    this.httpClient
      .get(this.http.BASE_URI + "qbo/config")
      .subscribe((response: any) => {
        console.log('config Response',response[0].is_connected)
        console.log(response[0].is_connected)
        if(response[0].is_connected==1){
          this.connectOption=true;
        }else{
          this.connectOption=false;
        }
      });
      
      let request = {redirect_url : window.location.href}
      this.httpClient
      .post(this.http.BASE_URI + "qbo/connect",request)
      .subscribe((response1: any) => {
      //  console.log('connect Response'+response1)
        this.connectionUrl = response1.data;
      });
  }
  syncQboInvoice() {
    this.ngxService.start();
    this.httpClient
      .get(this.http.BASE_URI + "qbo/getInvoice")
      .subscribe((response: any) => {
        // console.log("Response", response);
        this.ngxService.stop();
        Swal.fire("", "Invoice fetched successfully", "success");
        this.getdata();
      });
  }
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.elements.sort(function (a, b) {
      if (a[property].toLowerCase() < b[property].toLowerCase()) {
        return -1 * direction;
      }
      else if (a[property].toLowerCase() > b[property].toLowerCase()) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  sort1(property) {
    this.isDesc = !this.isDesc; //change the direction
    this.column = property;
    let direction = this.isDesc ? 1 : -1;
    this.elements.sort(function (a, b) {
    if (parseInt(a[property]) < parseInt(b[property])) {
    return -1 * direction;
    }
    else if (parseInt(a[property]) > parseInt(b[property])) {
    return 1 * direction;
    }
    else {
    return 0;
    }
    });
    };

    loadPage(e){
        this.numberOfRecords = (e - 1) * this.pageSize + this.pageSize > this.elements.length ? this.elements.length :  (e - 1) * this.pageSize + this.pageSize
    }
  //qbo connect
  qboConnect(access_url){
      if(access_url){
        this.spinner = true;
        window.open(access_url, 'Popup', 'toolbar=no,scrollbars=no,location=no,statusbar=no,menubar=no,resizable=0,width=500,height=400');
        console.log('err')
      }
  }
    //qbo disconnect 
   qboDisconnect(){
     Swal.fire({
      title: "Are you sure to disconnect?",
      // text: "You will not be able to recover this data!",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        this.spinner = true;
        this.httpClient
      .get(this.http.BASE_URI + "qbo/disconnect")
      .subscribe((response: any) => {
        // console.log("Response", response);
        this.ngxService.stop();
        Swal.fire("", "Disconnected successfully", "success");
        this.getdata();
        this.spinner = false;

        this.configQbo();
      });
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
      this.ngxService.stop();
    });
    }
}
