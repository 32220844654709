import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
  ElementRef,
} from "@angular/core";
import {
  faSort,
  faPlus,
  faEdit,
  faTimes,
  faHome,
  faCaretRight,
  faCheckSquare
} from "@fortawesome/free-solid-svg-icons";
import {
  MdbTableDirective,
  MdbTablePaginationComponent,
} from "angular-bootstrap-md";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { getMaxListeners } from "process";
import { HttpClient } from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { HttpProvider } from "../../common/http/http.provider";
import { HttpHeaders } from "@angular/common/http";
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import {​​​​​​SidenavComponent}​​​​​​ from "../sidenav/sidenav.component";
import {​​​​​​ Router }​​​​​​ from "@angular/router";
//import { canActive } from "../../common/helpers/admin_authgourd";
import { SelectionModel } from "@angular/cdk/collections";

declare var $: any;

@Component({
  selector: "app-backofficeuser-cargostatus",
  templateUrl: "./backofficeuser-cargostatus.component.html",
  styleUrls: ["./backofficeuser-cargostatus.component.css"],
})

export class BackofficeuserCargostatusComponent implements OnInit {
  constructor(
    private modalService: NgbModal,
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    private http: HttpProvider,
    private httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private component:SidenavComponent,
    private _router: Router
  ) {}
  showModel: any = false;
  submitButton: any = true;
  updateButton: any = false;
  registerForm: FormGroup;
  submitted = false;
  indexvalue: any = "";
  customer: any = {
    id: "",
    name: "",
  };
  request: any = "";
  closeResult: string;
  faSort = faSort;
  faPlus = faPlus;
  faEdit = faEdit;
  faTimes = faTimes;
  faHome = faHome;
  faCaretRight = faCaretRight;
  faCheckSquare = faCheckSquare;

  // @HostListener("input") oninput() {
  //   this.searchItems();
  // }
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  @ViewChild("privatUserCheckbox") privatUserCheckbox: ElementRef;
  @ViewChild("privatUserCheckbox1") privatUserCheckbox1: ElementRef;
  selection = new SelectionModel(true, []);
  selectionAmount : number;
  batch_submitted = false;
  showBatchModal: boolean = false;
  elements: any = [];
  cargoData: any = [];
  statusList:any = [];
  isDesc: boolean = false;
  column: string = "CategoryName";
  page = 1;
  currentPage = 1;
  noOfRecordsInCurrentPage = 10;
  pageSize = 25;
  totalStatusCount:number;
  numberOfRecords:number;
  headElements = ["shipper information","consignee information", "status"];
  searchText: string = "";
  previous: string;
  // indexvalue = 5;
  deleteoption:any=''
  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnInit(): void {
    if(localStorage.getItem("Role")=='BackOfficeSupervisor'||localStorage.getItem("Role")=='Admin'){
      this.deleteoption=true; 
    }
    this.ngxService.start();
    this.getcargoEntryStatus();
    this.getdata();
    this.registerForm = this.formBuilder.group({
      name: ["", Validators.required],
    });
  }
  homeLink(){
this.component.ngOnInit();
if(localStorage.getItem('Role')=='Admin'){
this._router.navigateByUrl("/adminDashboard");
}else{
this._router.navigateByUrl("/backofficeDashboard");
}
}

getcargoEntryStatus(e = 1){
  this.httpClient
  .get(this.http.BASE_URI + "status/cargoEntryStatus?page="+this.page)
  .subscribe((response: any) => {
    this.cargoData = [];
    this.totalStatusCount = response.totalCount;
    response.data.forEach(element => {
      this.cargoData.push(element)
      //this.loadPage(this.page)
    });
    this.numberOfRecords = (e - 1) * this.pageSize + this.pageSize > this.cargoData.length ? this.cargoData.length :  (e - 1) * this.pageSize + this.pageSize;
    this.ngxService.stop();
  });
}

searchItems() {
    const prev = this.mdbTable.getDataSource();
    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }
    if (this.searchText) {
      this.elements = this.mdbTable.searchLocalDataByMultipleFields(
        this.searchText,
        ["id", "status"]
      );
      this.mdbTable.setDataSource(prev);
    }
  }
  onChangeStatus(e, i) {
    this.showModel = true;
    this.updateButton = true;
    this.submitButton = false;
    // this.indexvalue = i;
    this.customer.id = e.id;
    this.customer.name = e.cargo_status;
    // console.log("e====>", e, i);
  }
  onRowRemove(e, i) {
    this.indexvalue = i;
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this data!",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        this.ngxService.start();
        this.httpClient
          .delete(this.http.BASE_URI + "status/delete/" + e.id)
          .subscribe((response: any) => {
            // console.log("Response", response);
            this.getdata();
            this.onReset();
            this.cancel();
            this.ngxService.stop();
          });
        Swal.fire("", "Status deleted successfully", "success");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  }
  get f() {
    return this.registerForm.controls;
  }
  onSubmitClick() {
    // console.log("onsubmit", true);
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.onSend("insert");
  }
  onUpdateClick() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.onSend("update");
  }
  onReset() {
    this.submitted = false;
    this.registerForm.reset();
    this.updateButton = false;
    this.submitButton = true;
  }
  aftersubmit() {
    // $('.modal').modal('hide');
    // $("#myModal").modal("hide");
    // Swal.fire('', 'You submitted succesfully!', 'success')
  }
  configFormModel() {
    this.onReset();
    this.showModel = true;
    // this.insertbutton = true;
  }
  cancel() {
    this.showModel = false;
  }
  onSelection(e) {
    // console.log('eventTriggerd\n')
    // console.log("ModelChange=====>", e)
  }
  getdata() {
    this.httpClient
      .get(this.http.BASE_URI + "status/liststatus")
      .subscribe((response: any) => {
        this.elements = response.data;

       // console.log("statusList", this.elements)
        // this.mdbTable.setDataSource(this.elements);
        // this.previous = this.mdbTable.getDataSource();
        // this.cdRef.detectChanges();
        this.ngxService.stop();
      });
  }
  onSend(e) {
    
    this.ngxService.start();
    if(this.batch_submitted){
      //this will work on batch option
    //  let header = new HttpHeaders();
    //  header.set("Access-Control-Allow-Origin", "*");
      this.httpClient
      .post(this.http.BASE_URI + "status/addBatchOption", {
        status: this.customer.name,
        cargo_ids: this.logSelection(),
      })
      .subscribe((response: any) => {
        this.ngxService.start();
        this.showBatchModal = false;
        this.privatUserCheckbox["checked"] = false;
        this.privatUserCheckbox["indeterminate"] = false;
        this.selection = new SelectionModel(true, []);
        this.getcargoEntryStatus();
        Swal.fire("", "Batch Option Added Successfully..", "success");
        this.ngxService.stop();
        this.onReset();
        this.cancel();
        this.batch_submitted = false;
      });
      return;
    }
    if (e == "insert") {
      let request = {
        cargo_status: this.customer.name,
        created_by:localStorage.getItem("Id")
      };
     
      this.httpClient
        .post(this.http.BASE_URI + "status/add", request)
        .subscribe((response: any) => {
          // console.log("Response", response);
          this.getdata();
          this.onReset();
          this.customer.id = "";
          this.cancel();
        });
      Swal.fire("", "Your Data Inserted Succesfully!", "success");
    } else if(e == 'update') {
      let request = {
        status: this.customer.name,
        created_by:localStorage.getItem("Id")
      };
      let header = new HttpHeaders();
      header.set("Access-Control-Allow-Origin", "*");
      this.httpClient
        .post(this.http.BASE_URI + "status/update/"+this.customer.id, request)
        .subscribe((response: any) => {
          // console.log("Response", response);
          var status = response.success;
          if(status){
           Swal.fire("", response.message, "success");
          }
          this.getcargoEntryStatus();
          this.onReset();
          this.customer.id = "";
          this.cancel();
        });
    }
  }
  sort(property) {
    // console.log(property)
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;
    this.cargoData.sort(function (a, b) {
      a[property]=(a[property]==null)?" ":a[property]
b[property]=(b[property]==null)?" ":b[property]
      if (a[property].toLowerCase() < b[property].toLowerCase()) {
        return -1 * direction;
      }
      else if (a[property].toLowerCase() > b[property].toLowerCase()) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
    //showing pagination count
    loadPage(e){
      this.selection.clear();
      this.numberOfRecords = (e - 1) * this.pageSize + this.pageSize > this.cargoData.length ? this.cargoData.length :  (e - 1) * this.pageSize + this.pageSize;
      this.page = e;
      this.getcargoEntryStatus(e) 
    }

  masterToggle() {
    this.isAllSelected()
      ? this.clearAll()
      :  this.selectRows();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const page = this.pageSize;
    let x = this.numberOfRecords % this.pageSize;
    if(x != 0){
      this.noOfRecordsInCurrentPage = x;
    }else{
      this.noOfRecordsInCurrentPage = this.pageSize;
    }
    if(numSelected == this.noOfRecordsInCurrentPage){
      return true;
    }else{
      return false;
    }
    //return numSelected === page;
  }
  
  onBatch() {
    this.batch_submitted = true;
    this.registerForm.reset();
    this.showModel = true;
    this.updateButton = true;
    this.submitButton = false;
  }
  
  clearAll(){
    this.selection.clear();
  }

  selectRows() {
    this.selection.clear();
    let start = (this.pageSize * (this.page - 1));
    let end = (this.numberOfRecords) ;
    for (let index = 0; index < this.pageSize; index++) {
      this.selection.select(this.cargoData[index]);
      this.selectionAmount = this.selection.selected.length;
    }
  }

  logSelection() {
    let id = [];
    this.selection.selected.forEach(async (s) => await id.push(s.id));
    return id;
  }

  addRemove(e,el,i){
    const isChecked = e.checked; // stored checked value true or false
    if(isChecked) {
      this.selection.selected.push(el);
    }else{
       this.selection.selected.splice(i, 1);
    }
  }
  isAnyItemChecked(){
    if(this.selection.selected.length > 0){
      return true;
    }else{
      return false;
    }
  }
}
