import { Component, OnInit } from '@angular/core';
import { Router } from "@angular/router";
import { MatTable } from "@angular/material/table";
import { HttpClient } from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { HttpProvider } from "../../common/http/http.provider";
import { NgxUiLoaderService } from "ngx-ui-loader";
import { SidenavComponent } from "../sidenav/sidenav.component";
import {
  faCog,
  faPlus,
  faSearch,
  faBars,
  faEye,
  faTachometerAlt,
  faEdit,
  faTimes,
  faSort,
  faCheck,
  faCheckDouble,
  faOutdent,
  faFileInvoice,
  faShoppingBag,
  faFile,
  faIdBadge,
  faStickyNote,
  faUser,
  faCaretDown,
  faCaretUp,
  faSignOutAlt,
  faCaretRight,
  faHome,
  faCamera,
} from "@fortawesome/free-solid-svg-icons";
@Component({
  selector: 'app-dataentry-log',
  templateUrl: './dataentry-log.component.html',
  styleUrls: ['./dataentry-log.component.css']
})
export class DataentryLogComponent implements OnInit {
  CargoType:any='';
  faHome = faHome;
  faCaretRight = faCaretRight;
  constructor(
    private _router: Router,
    private http: HttpProvider,
    private httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private component: SidenavComponent
  ) { }

  ngOnInit(): void {
    let str = window.location.href;
    let myArr = str.split("/cargoentry/");
    let cargoUrl = myArr[1].split("/timeline/");
    let cargoId = cargoUrl[1];
    this.CargoType=cargoUrl[0]
    // console.log(cargoUrl)
  }

  homeLink() {
    this.component.ngOnInit();
    if (localStorage.getItem("Role") == "Admin") {
      this._router.navigateByUrl("/adminDashboard");
    } else {
      this._router.navigateByUrl("/backofficeDashboard");
    }
  }

}
