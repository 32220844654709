// import { Component, OnInit } from '@angular/core';
import { Component, ViewChild, OnInit } from "@angular/core";
import { MatSidenav } from "@angular/material/sidenav";
import { MaterialModule } from "src/app/common/material.module";
import { faBars,faHome,faCaretRight } from "@fortawesome/free-solid-svg-icons";
// import { canActive } from "../../common/helpers/admin_authgourd";
//import { ChartOptions, ChartType, ChartDataSets } from 'chart.js';
import { HttpClient } from "@angular/common/http";
import { HttpProvider } from "../../common/http/http.provider";
import { NgxUiLoaderService } from "ngx-ui-loader";
//import { Label } from 'ng2-charts';
// import {SharedModule} from '../../common/shared.module'
/**
 * @title Autosize sidenav
 */

@Component({
  selector: "app-warehouse-dashboard",
  templateUrl: "./warehouse-dashboard.component.html",
  styleUrls: ["./warehouse-dashboard.component.css"],
})
export class WarehouseDashboardComponent implements OnInit {
  constructor(private http: HttpProvider, private httpClient: HttpClient,private ngxService: NgxUiLoaderService) {
   
  }
  
  cargoCount: any = [];
  cargoDataAirOcean:any=[]
  name = "Angular";
  width: number = 700;
  height: number = 300;
  fitContainer: boolean = false;

  view: any[] = [600, 400];
  // options for the chart
  showXAxis = true;
  showYAxis = true;
  gradient = true;
  showLegend = true;
  showXAxisLabel = true;
  xAxisLabel = "Days";
  showYAxisLabel = true;
  yAxisLabel = "Cargo Count";
  timeline = true;
  doughnut = true;
  title = "Cargo";
  colorScheme = {
    domain: ["#9370DB", "#87CEFA", "#FA8072", "#FF7F50", "#90EE90", "#9370DB"],
  };
  //pie
  showLabels = true;
  public pieChartData:number[] = [0, 1, 0 ,0, 1,1];
  airChart:any=[];
  oceanChart:any=[];
  public pieChartLabels = ['Monday', 'Tuesday', 'Wednesday','Thursday','Friday','Saturday'];
  public pieChartType:string = 'pie';
  public  Color = [{
      backgroundColor: [
        'red',
        'green',
        'blue',
        'yellow',
        'black',
        // "#9370DB", "#87CEFA", "#FA8072", "#FF7F50", "#90EE90", "#9370DB"
      ]}
  ];
  public chartClicked(e:any):void {
    // console.log(e);
  }
  public chartHovered(e:any):void {
    // console.log(e);
  }
  public multi = [
    {
      name: "Monday",
      series: [
        {
          name: "Air",
          value: 12,
        },
        {
          name: "Ocean",
          value: 32,
        },
      ],
    },
    {
      name: "Tuesday",
      series: [
        {
          name: "Air",
          value: 7,
        },
        {
          name: "Ocean",
          value: 9,
        },
      ],
    },
    {
      name: "Wednesday",
      series: [
        {
          name: "Air",
          value: 54,
        },
        {
          name: "Ocean",
          value: 32,
        },
      ],
    },
    {
      name: "Thursday",
      series: [
        {
          name: "Air",
          value: 76,
        },
        {
          name: "Ocean",
          value: 19,
        },
      ],
    },
    {
      name: "Friday",
      series: [
        {
          name: "Air",
          value: 4,
        },
        {
          name: "Ocean",
          value: 45,
        },
      ],
    },
  ];
  ngOnInit(): void {
    this.getCargoCount();
  }
  FaBars = faBars;
  faHome = faHome;
  faCaretRight = faCaretRight;
  @ViewChild("sidenav") sidenav: MatSidenav;

  isExpanded = true;
  showSubmenu: boolean = true;
  isShowing = false;
  showSubSubMenu: boolean = true;
  mouseenter() {
    // if (!this.isExpanded) {
    //   this.isShowing = true;
    // }
  }

  mouseleave() {
    //   if (!this.isExpanded) {
    //     this.isShowing = false;
    //   }
  }
   getCargoCount() {
    this.httpClient
      .get(this.http.BASE_URI + "getCargoCount")
      .subscribe( async(response: any) => {
        this.ngxService.start();
        // console.log("Response", response.air_ocean_day_wise_result);  
        this.cargoCount = response;
        // this.cargoCount.air_ocean_day_wise_result.forEach(async element => {
        //   this.airChart.push(element.series);
        //   this.oceanChart.push(element);
        // });
        this.cargoDataAirOcean=await this.cargoCount.air_ocean_day_wise_result;
        this.ngxService.stop();
        // console.log('Multi\t',this.airChart,this.oceanChart);
        //  console.log('NewOne\t',await this.cargoCount.air_ocean_day_wise_result)
        // this.multi=response.
      });
  }
}
