import { Component, HostListener, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { faSort, faPlus, faEdit, faTimes } from '@fortawesome/free-solid-svg-icons';
import { MdbTableDirective, MdbTablePaginationComponent } from 'angular-bootstrap-md';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from "ngx-ui-loader";
import { HttpClient } from "@angular/common/http";
import { HttpProvider } from "../../common/http/http.provider";
import { GlobalConstants } from "../../common/helpers/global_variables";
import { Router } from "@angular/router";

import Swal from 'sweetalert2/dist/sweetalert2.js';
declare var $: any;
import { canActive } from "../../common/helpers/admin_authgourd";
@Component({
  selector: 'app-warehouse-checkout',
  templateUrl: './warehouse-checkout.component.html',
  styleUrls: ['./warehouse-checkout.component.css']
})
export class WarehouseCheckoutComponent implements OnInit {
  title = 'appBootstrap';
  showModel: any = false;
  submitButton:any=true;
  updateButton:any=false
  registerForm: FormGroup;
  submitted = false;
  indexvalue:any="";
  cargo: any = {
    trackno: "",
    status: "",
    averageShipingtime: "",
    shipmentNo: "",
    customerId: ""
  }
  model: NgbDateStruct;
  date: { year: number, month: number };
  dataEntry: any = [{
    "id": 1,
    "trackno": "AS234FG345",
    "status": "Received",
    "averageShipingtime": "Tue Aug 10 2021 05:59:39 GMT+0530 (India Standard Time)",
    "shipmentNo": "CA123123",
    "customerId": "UR120041"

  }, {
    "id": 2,
    "trackno": "AS234FG346",
    "status": "Received",
    "averageShipingtime": "Tue Aug 10 2021 07:26:02 GMT+0530 (India Standard Time)",
    "shipmentNo": "CA123456",
    "customerId": "UR120042"

  }, {
    "id": 3,
    "trackno": "AS234FG347",
    "status": "Received",
    "averageShipingtime": "Tue Aug 02 2021 05:16:02 GMT+0530 (India Standard Time)",
    "shipmentNo": "CB123563",
    "customerId": "UR120043"

  }, {
    "id": 4,
    "trackno": "AS234FG348",
    "status": "Shipped",
    "averageShipingtime": "Tue Aug 19 2021 15:26:02 GMT+0530 (India Standard Time)",
    "shipmentNo": "CA481123",
    "customerId": "UR120044"
  }];
  closeResult: string;
  faSort = faSort;
  faPlus = faPlus;
  faEdit = faEdit;
  faTimes = faTimes;
  cargoCheckInCheckOutData: any = [];
  cargoElements: any = [];
  cargoItems: any = [];
  page = 1;
  pageSize = 10;
  headElements = [
    "id",
    "shipper information",
    "consignee information",
    "date",
    "tracking number",
    "status"
  ];
  searchText: string = "";
  previous: string;
  deleteoption: any = "";
  cargoheadElements1 = [
     "Pieces",
     'length',
     'weight',
     'height',
     'pounds',
     'cubic feet',
     'cw',
     'reference'
  ];
  // @HostListener('input') oninput() {
  //   this.searchItems();
  // }
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  elements: any = [];

  // indexvalue = 5;
  constructor(
    private _router: Router,

    private modalService: NgbModal, private cdRef: ChangeDetectorRef, private formBuilder: FormBuilder, private calendar: NgbCalendar, private http: HttpProvider,
    private ngxService: NgxUiLoaderService,
    private httpClient: HttpClient,) {}
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnInit(): void {
    if(localStorage.getItem("Role")=='WareHouseSupervisor'||localStorage.getItem("Role")=='Admin'){
      this.deleteoption=true; 
    }
    this.registerForm = this.formBuilder.group({
      status: ['', Validators.required],
      trackno: ['', Validators.required],
      shipmentno: ['', Validators.required],
      customer: ['', Validators.required],
      shipmentaveragetime: ['', Validators.required],
    });
    // this.elements.push(this.dataEntry);
    this.elements = this.dataEntry;
    this.mdbTable.setDataSource(this.elements);
    this.previous = this.mdbTable.getDataSource();
    this.cdRef.detectChanges();
    this.getcargoCheckInCheckOut();
  }
  searchItems() {
    const prev = this.mdbTable.getDataSource();
    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }
    if (this.searchText) {
      this.elements = this.mdbTable.searchLocalDataByMultipleFields(this.searchText, ['id', 'track no', 'status', 'average shiping time', 'shipment no', 'customer id']);
      this.mdbTable.setDataSource(prev);
    }
  }
  onRowCreate(e, i) {
    GlobalConstants.cragoId = e.details.id;
    GlobalConstants.cargoEntrySavebtn = false;
    GlobalConstants.cargoEntryUpdatebtn = true;
    this._router.navigateByUrl("/warehouse/cargoentry/air");
  }
  onRowRemove(e, i) {
    // console.log(e);
    this.indexvalue = i;
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this data!",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        this.httpClient
          .delete(this.http.BASE_URI + "deleteCargo/" + e.details.id)
          .subscribe((response: any) => {
            // console.log("Response", response);
            this.ngxService.start();
          //  this.getdata();
          });
        Swal.fire("Deleted!", "Your data has been deleted.", "success");
        // For more information about handling dismissals please visit
        // https://sweetalert2.github.io/#handling-dismissals
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  }
  // convenience getter for easy access to form fields
  get f() { return this.registerForm.controls; }
  onSubmitClick() {
    // console.log('onsubmit', this.cargo)
    this.submitted = true;
    // this.submitButton=false;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    this.elements.push(this.cargo);
    //  $("#mymodal").modal("hide");
    Swal.fire('Thank you...', 'You submitted succesfully!', 'success');
    this.showModel = false;
    //  
    //  alert('SUCCESS!! :-)\n\n' + JSON.stringify(this.cargo));
    //    this.cargo={
    //     trackno:"",
    //     status:"",
    //     averageShipingtime:new Date(),
    //     shipmentNo:"",
    //     customerId:""
    // }
    // display form values on success

  }
  onUpdateClick() {
    this.submitted = true;
    // this.submitButton=false;
    // stop here if form is invalid
    if (this.registerForm.invalid) {
      return;
    }
    this.elements[this.indexvalue] = this.cargo;
    Swal.fire('Thank you...', 'Your Data Edited Succesfully!', 'success');
    this.showModel = false;
  }
  onReset() {
    this.submitted = false;
    this.registerForm.reset();
    this.updateButton = false;
    this.submitButton=true;
  }
  aftersubmit() {
    $('.modal').modal('hide');
    // $("#myModal").modal("hide");
    Swal.fire('Thank you...', 'You submitted succesfully!', 'success')
  }
  configFormModel() {
    this.onReset();
    this.showModel = true;
    // this.insertbutton = true;
  }
  cancel() {
    this.showModel = false;
    // this.submitted = false;
    // this.ConfigFormGroup.reset();
  }
  getcargoCheckInCheckOut(){
    this.httpClient
    .get(this.http.BASE_URI + "getcargoCheckInCheckOut/air/checkout")
    .subscribe((response: any) => {
      // console.log("Response", response);
      this.cargoCheckInCheckOutData = response.air.checkout;
      this.ngxService.stop();
    });
  }
}
