import {
  Component,
  HostListener,
  OnInit,
  ViewChild,
  ChangeDetectorRef,
} from "@angular/core";
import {
  faSort,
  faPlus,
  faEdit,
  faTimes,
} from "@fortawesome/free-solid-svg-icons";
import {
  MdbTableDirective,
  MdbTablePaginationComponent,
} from "angular-bootstrap-md";
import { Router } from "@angular/router";
import { NgbModal, ModalDismissReasons } from "@ng-bootstrap/ng-bootstrap";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbDateStruct, NgbCalendar } from "@ng-bootstrap/ng-bootstrap";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { getMaxListeners } from "process";
import { HttpClient } from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { HttpProvider } from "../../common/http/http.provider";
import { HttpHeaders } from "@angular/common/http";
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
declare var $: any;
import { GlobalConstants } from "../../common/helpers/global_variables";
//import { canActive } from "../../common/helpers/admin_authgourd";
@Component({
  selector: "app-roles",
  templateUrl: "./roles.component.html",
  styleUrls: ["./roles.component.css"],
})
export class RolesComponent implements OnInit {
  constructor(
    private modalService: NgbModal,
    private cdRef: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private calendar: NgbCalendar,
    private http: HttpProvider,
    private httpClient: HttpClient,
    private ngxService: NgxUiLoaderService,
    private _router: Router
  ) {}
  showModel: any = false;
  submitButton: any = true;
  updateButton: any = false;
  registerForm: FormGroup;
  submitted = false;
  indexvalue: any = "";
  check: any = true;
  customer: any = {
    id: "",
    name: "",
  };
  request: any = "";
  closeResult: string;
  faSort = faSort;
  faPlus = faPlus;
  faEdit = faEdit;
  faTimes = faTimes;
  // @HostListener("input") oninput() {
  //   this.searchItems();
  // }
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  elements: any = [];
  page = 1;
  pageSize = 10;
  headElements = ["id", "roles"];
  searchText: string = "";
  previous: string;
  // indexvalue = 5;

  open(content) {
    this.modalService
      .open(content, { ariaLabelledBy: "modal-basic-title" })
      .result.then(
        (result) => {
          this.closeResult = `Closed with: ${result}`;
        },
        (reason) => {
          this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return "by pressing ESC";
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return "by clicking on a backdrop";
    } else {
      return `with: ${reason}`;
    }
  }
  onFlagChange(e) {
    // console.log(e);
  }
  checkValue() {
    // console.log(this.check);
  }
  ngOnInit(): void {
    // if (canActive("admin") == false) {
    //   let str = window.location.href;
    //   let myArr = str.split("#");
    //   window.location.replace(myArr[0]+"#/login");
    // }
    this.ngxService.start();
    this.getdata();
    this.registerForm = this.formBuilder.group({
      name: ["", Validators.required],
    });
  }
  searchItems() {
    const prev = this.mdbTable.getDataSource();
    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }
    if (this.searchText) {
      this.elements = this.mdbTable.searchLocalDataByMultipleFields(
        this.searchText,
        ["id", "roles"]
      );
      this.mdbTable.setDataSource(prev);
    }
  }
  reloadCurrentRoute() {
    let currentUrl = this._router.url;
    this._router.navigateByUrl("/", { skipLocationChange: true }).then(() => {
      this._router.navigate([currentUrl]);
    });
  }
  onRowCreate(e, i) {
    this.showModel = true;
    this.updateButton = true;
    this.submitButton = false;
    // this.indexvalue = i;
    this.customer.id = e.id;
    this.customer.name = e.role;
    // console.log("e====>", e, i);
  }
  onRowRemove(e, i) {
    this.indexvalue = i;
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to recover this data!",
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then((result) => {
      if (result.isConfirmed) {
        this.ngxService.start();
        this.httpClient
          .delete(this.http.BASE_URI + "role/delete/" + e.id)
          .subscribe((response: any) => {
            // console.log("Response", response);
            this.getdata();
            this.onReset();
            this.cancel();
            this.ngxService.stop();
          });
        Swal.fire("Deleted!", "Your data has been deleted.", "success");
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        //Swal.fire("Cancelled", "Your data is safe :)", "error");
      }
    });
  }
  get f() {
    return this.registerForm.controls;
  }
  onSubmitClick() {
    // console.log("onsubmit", true);
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.onSend("insert");
  }
  onUpdateClick() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.onSend("update");
  }
  onReset() {
    this.submitted = false;
    this.registerForm.reset();
    this.updateButton = false;
    this.submitButton = true;
  }
  aftersubmit() {
    // $('.modal').modal('hide');
    // $("#myModal").modal("hide");
    // Swal.fire('Thank you...', 'You submitted succesfully!', 'success')
  }
  configFormModel() {
    this.onReset();
    this.showModel = true;
    GlobalConstants.isExpanded = false;
    // console.log(GlobalConstants.isExpanded);
    // this.insertbutton = true;
  }
  cancel() {
    this.showModel = false;
  }
  onSelection(e) {
    // console.log('eventTriggerd\n')
    // console.log("ModelChange=====>", e)
  }
  getdata() {
    this.httpClient
      .get(this.http.BASE_URI + "role/listroles")
      .subscribe((response: any) => {
        // console.log("Response", response)
        this.elements = response.message;
        this.mdbTable.setDataSource(this.elements);
        this.previous = this.mdbTable.getDataSource();
        this.cdRef.detectChanges();
        this.ngxService.stop();
      });
  }
  onSend(e) {
    this.ngxService.start();
    if (e == "insert") {
      let request = {
        role: this.customer.name,
      };
      let header = new HttpHeaders();
      header.set("Access-Control-Allow-Origin", "*");
      this.httpClient
        .post(this.http.BASE_URI + "role/add", request)
        .subscribe((response: any) => {
          // console.log("Response", response);
          this.getdata();
          this.onReset();
          this.customer.id = "";
          this.cancel();
        });
      Swal.fire("Thank you...", "Your Data Inserted Succesfully!", "success");
    } else {
      let request = {
        role: this.customer.name,
      };
      let header = new HttpHeaders();
      header.set("Access-Control-Allow-Origin", "*");
      this.httpClient
        .post(this.http.BASE_URI + "role/update/" + this.customer.id, request)
        .subscribe((response: any) => {
          // console.log("Response", response);
          this.getdata();
          this.onReset();
          this.customer.id = "";
          this.cancel();
        });
      Swal.fire("Thank you...", "Your Data Edited Succesfully!", "success");
    }
  }
}
