import { Component, HostListener, OnInit, ViewChild, ChangeDetectorRef } from '@angular/core';
import { faSort, faPlus, faEdit, faTimes,faCaretRight,faHome,faCheck,faMinus,faPlusCircle,faTimesCircle,faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { MdbTableDirective, MdbTablePaginationComponent } from 'angular-bootstrap-md';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDateStruct, NgbCalendar } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2/dist/sweetalert2.js';
import { getMaxListeners } from 'process';
import { HttpClient } from "@angular/common/http";
import { environment } from "./../../../environments/environment";
import { HttpProvider } from "../../common/http/http.provider";
import { HttpHeaders } from '@angular/common/http';
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import {​​​​​​SidenavComponent}​​​​​​ from "../sidenav/sidenav.component";
import {​​​​​​ Router }​​​​​​ from "@angular/router";
import * as _ from "lodash";
import { truncateSync } from 'fs';

declare var $: any;
@Component({
  selector: 'app-customersupport-customer',
  templateUrl: './customersupport-customer.component.html',
  styleUrls: ['./customersupport-customer.component.css']
})
export class CustomersupportCustomerComponent implements OnInit {
  constructor(private modalService: NgbModal, private cdRef: ChangeDetectorRef, private formBuilder: FormBuilder, private calendar: NgbCalendar, private http: HttpProvider, private httpClient: HttpClient, private ngxService: NgxUiLoaderService,private component:SidenavComponent,private _router: Router) {
    
   }
   deleteoption:any='';
   surinameBillingFields : any = true;
   surinameShippingFields : any = true;

  deleteInactive:any = true;
  showModel: any = false;
  submitButton: any = true;
  updateButton: any = false
  registerForm: FormGroup;
  submitted = false;
  stateshow: boolean = false;
  citieseshow: boolean = false;
  indexvalue: any = "";
  roles: any = [];
  countries: any = '';
  countryvalues:any='';
  states: any = '';
  cities: any = "";
  scountries: any = '';
  scountryvalues:any='';
  sstates: any = '';
  scities: any = "";
  profilePicture:any="";
  isProfiled:any="";
  isIntial:boolean = false;
  intials:any = '';
  customeractive:boolean = false;
  sameaddress:any = '';
  
  customer: any = {
    id:"",
    firstname: "",
    lastname: "",
    company_name: "",
    email: "",
    secondary_email : "",
    additional_email : "",
    mobile: "",
    baddress: "",
    bcountry: "",
    bstate: "",
    bcity: "",
    bzipcode: "",
    saddress: "",
    scountry: "",
    sstate: "",
    scity: "",
    szipcode: "",
    created_by:"",
    customeractive: "",
    sameaddress : ''
  }
  request: any = ""
  closeResult: string;
  faSort = faSort;
  faPlus = faPlus;
  faEdit = faEdit;
  faTimes = faTimes;
  faHome = faHome;
  faCheck  = faCheck;
  faCaretRight = faCaretRight;
  faMinus = faMinus;
  faPlusCircle=faPlusCircle;
  faTimesCircle = faTimesCircle;
  faCheckCircle = faCheckCircle;
  check: any = true;
  isCustomerAdd = false;
  // @HostListener('input') oninput() {
  //   this.searchItems();
  // }
  @ViewChild(MdbTableDirective, { static: true }) mdbTable: MdbTableDirective;
  elements: any = [];
  isDesc: boolean = false;
  column: string = "CategoryName";
  page = 1;
  pageSize = 25;
  numberOfRecords : number;
  headElements = ['name', 'email', 'mobile','billing country' ,'created by'];
  searchText: string = '';
  previous: string;
  // indexvalue = 5;

  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  ngOnInit(): void {
    if(localStorage.getItem("Role")=='CustomerSupportSupervisor'||localStorage.getItem("Role")=='Admin'){
      this.deleteoption=true; 
    }
    this.ngxService.start();
    this.getdata();
    this.customer.created_by=localStorage.getItem("Id");
    this.registerForm = this.formBuilder.group({
      firstname: ['', Validators.required],
      lastname: ['', Validators.required],
      company_name: ['',Validators.required],
      sameaddress:[''],
      email: ['', [Validators.required,Validators.email]],
      secondary_email:['',[Validators.email]],
      additional_email:['',[Validators.email]],
      customeractive:[''],
      mobile: ['', [Validators.required,Validators.minLength(6)]],
      baddress: ['', Validators.required],
      bcountry: ['', Validators.required],
      bstate: [''],
      bcity: [''],
      bzipcode: ['', Validators.required],
      saddress: ['', Validators.required],
      scountry: ['', Validators.required],
      sstate: [''],
      scity: [''],
      szipcode: ['', Validators.required],
    });
    // this.elements.push(this.dataEntry);
  }
  homeLink(){
this.component.ngOnInit();
if(localStorage.getItem('Role')=='Admin'){
this._router.navigateByUrl("/adminDashboard");
}else{
this._router.navigateByUrl("/backofficeDashboard");
}
}
subAccount(e){
  this._router.navigateByUrl("/subCustomer/"+e);
}
  searchItems() {
    const prev = this.mdbTable.getDataSource();
    if (!this.searchText) {
      this.mdbTable.setDataSource(this.previous);
      this.elements = this.mdbTable.getDataSource();
    }
    if (this.searchText) {
      this.elements = this.mdbTable.searchLocalDataByMultipleFields(this.searchText, ['name', 'email', 'mobile', 'address', 'created by']);
      this.mdbTable.setDataSource(prev);
    }
  }
  onRowCreate(e, i) {
    console.log('CUstomerData',e)
    this.isCustomerAdd = false;
    this.showModel = true;
    this.updateButton = true;
    this.submitButton = false;
   // this.sameaddress = false;

    // this.indexvalue = i;
    this.customer.id=e.id;
    this.customer.firstname = e.first_name;
    this.customer.lastname=e.last_name;
    this.customer.company_name=e.company_name;
    this.customer.mobile = e.phone;
    this.customer.email = e.email;
    this.customer.secondary_email = e.secondary_email;
    this.customer.additional_email = e.additional_email;
    this.customer.created_by=e.created_by;
    this.customer.baddress = e.billing_address;
    this.customer.bcountry = e.billing_country
    this.customer.bstate = e.billing_state;
    this.customer.bcity = e.billing_city;
    this.customer.bzipcode = e.billing_zipcode;
    this.customer.saddress = e.shipping_address;
    this.customer.scountry = e.shipping_country
    this.customer.sstate = e.shipping_state;
    this.customer.scity = e.shipping_city;
    this.customer.szipcode = e.shipping_zipcode;
    //this.customer.sameaddress = e.same_as_address;
    if (e.is_customer_active == "yes") {
      this.customer.customeractive = true;
    } else {
      this.customer.customeractive = false;
    }
    if (e.same_as_address == "yes") {
      this.sameaddress = true;
    //   console.log("eeee=>>>" + e.same_as_address);
      this.customer.sameaddress = true;
    } else {
      this.sameaddress = false;
      this.customer.sameaddress = false;
    }
    if(e.first_name != "" && e.first_name != "NULL" && e.first_name != undefined && e.last_name != "" && e.last_name != "NULL" && e.last_name != undefined){
      var firstName = e.first_name;
      var lastName = e.last_name;
      this.intials = firstName.charAt(0) + lastName.charAt(0);
    }
    
    if (e.image != "" || e.image != "NULL" || e.image != undefined ) {
        if(e.image == null || e.image == 'null'){
           this.isProfiled = '';
           this.isIntial = true;
          //  console.log("image====>"+ e.image);
        }else{
          this.profilePicture = e.image;
          this.isProfiled = true;
          this.isIntial = false;
          // console.log('ssssss----'+ this.profilePicture)
        }
    }
    // if (e.image != "" || e.image != "NULL" || e.image != undefined) {
    //   this.profilePicture = e.image;
    //   this.isProfiled = true;
    // }
    // console.log('e====>', e, i);

  }
  onRowRemove(e, i) {
    this.indexvalue = i;
    Swal.fire({
      title: 'Are you sure want to delete this customer?',
      text: '',
      // icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes',
      cancelButtonText: 'Cancel'
    }).then((result) => {
      if (result.isConfirmed) {
        this.ngxService.start();
        this.httpClient
          .post(this.http.BASE_URI + 'deactivateCustomer/'+e.id, "")
          .subscribe((response: any) => {
            // console.log("Response", response)
            this.check = !this.check;
            this.getdata();
            this.onReset();
            this.cancel();
            this.ngxService.stop();
          });
        Swal.fire(
          '',
          'Customer deleted successfully',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        // Swal.fire(
        //   'Cancelled',
        //   'Your data is safe :)',
        //   'error'
        // )
      }
    })
  }
  get f() { return this.registerForm.controls; }
  onSubmitClick() {
    // console.log('onsubmit', this.customer)
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.onSend('insert');
  }
  onUpdateClick() {
    this.submitted = true;
    if (this.registerForm.invalid) {
      return;
    }
    this.onSend('update')
  }
  onReset() {
    this.submitted = false;
    this.registerForm.reset();
    this.updateButton = false;
    this.submitButton = true;
  }
  aftersubmit() {
  }
  checkValue() {
     console.log(this.check); 
     this.getdata();  
  }
  configFormModel() {
    this.onReset();
    this.showModel = true;
    this.isCustomerAdd = true;
    this.customer.id = '';
    this.customeractive = !this.customeractive;
  //  console.log('configFormModel',this.customeractive)
   // this.sameaddress = false;
    this.isProfiled = false;
    this.intials = '';
    this.isIntial = false;
    // this.insertbutton = true;
  }
  cancel() {
    this.showModel = false;
    this.customeractive = false;
    //console.log(this.customeractive)
  }
  getKeyByValue(object, value) {
    return Object.keys(object).find((key) => object[key] === value);
  }
  Bcountryselect(e) {
     
    if (!e) {
      return;
    }

    if(e == 'Suriname'){
      this.surinameBillingFields = true;
      console.log("\n country value===>", e)
    }else{
      this.surinameBillingFields = false;
    }
    //this.ngxService.start();
    this.httpClient
      .get(this.http.BASE_URI + 'statelist/' + this.getKeyByValue(this.countries, e))
      .subscribe((response: any) => {
        // console.log("Response", response)
        this.states = response;
        this.stateshow = true;
      //  this.ngxService.stop();
      });
  }
  Bstateselect(e) {
    if (e == null || e == "") {
      return
    }
    // console.log("\n country value===>", this.customer.country)
  //  this.ngxService.start();
    this.httpClient
      .get(this.http.BASE_URI + 'citylist/' + this.getKeyByValue(this.countries, this.customer.bcountry))
      .subscribe((response: any) => {
        // console.log("Response", response)
        this.cities = response;
        this.citieseshow = true;
        this.ngxService.stop();
      });
  }
  Scountryselect(e) {
    if (!e) {
      return;
    }
    if(e == 'Suriname'){
      this.surinameShippingFields = true;
      console.log("\n Shipping country value===>", e)
    }else{
      this.surinameShippingFields = false;
    }
    // console.log("\n country value===>", e)
   // this.ngxService.start();
    this.httpClient
      .get(this.http.BASE_URI + 'statelist/' + this.getKeyByValue(this.scountries, e))
      .subscribe((response: any) => {
        // console.log("Response", response)
        this.sstates = response;
        this.stateshow = true;
        this.ngxService.stop();
      });
  }
  Sstateselect(e) {
    if (e == null || e == "") {
      return
    }
    // console.log("\n country value===>", this.customer.country)
    //this.ngxService.start();
    this.httpClient
      .get(this.http.BASE_URI + 'citylist/' +this.getKeyByValue(this.scountries, this.customer.scountry) )
      .subscribe((response: any) => {
        // console.log("Response", response)
        this.scities = response;
        this.citieseshow = true;
        this.ngxService.stop();
      });
  }
  onSelection(e) {
    // console.log('eventTriggerd\n')
    // console.log("ModelChange=====>", e)
  }
  activeCustomer(e) {
    // console.log("testing", e);
  }
  getdata() {
    if(this.check == true){
      this.ngxService.start();
      this.deleteInactive = true;
      this.httpClient
      .get(this.http.BASE_URI + 'listCustomers')
      .subscribe((response: any) => {
        //this.elements = response.data.customers.all;
        this.elements = response.data.customers.active;
        this.countries = response.data.countries;
        this.scountries = response.data.countries;
        this.countryvalues = Object.values(response.data.countries);
        this.scountryvalues = Object.values(response.data.countries);
       // console.log('All CUstomer->>>>>>>>',this.elements)
        // this.mdbTable.setDataSource(this.elements);
        // this.previous = this.mdbTable.getDataSource();
        // this.cdRef.detectChanges();
        this.ngxService.stop();
        this.check= false;
      });
    }else{
      this.ngxService.start();
    //  this.deleteInactive = false;
      this.httpClient
      .get(this.http.BASE_URI + 'listCustomers')
      .subscribe((response: any) => {
        this.elements = response.data.customers.deactive;
        this.countries = response.data.countries;
        this.scountries = response.data.countries;
        this.countryvalues = Object.values(response.data.countries);
        this.scountryvalues = Object.values(response.data.countries);
       // console.log('deactive CUstomer->>>>>>>>',this.elements)
        // this.mdbTable.setDataSource(this.elements);
        // this.previous = this.mdbTable.getDataSource();
        // this.cdRef.detectChanges();
        this.ngxService.stop();
        this.check= true;
      });
    }
    // this.httpClient
    //   .get(this.http.BASE_URI + 'listCustomers')
    //   .subscribe((response: any) => {
    //     // console.log("Response", response)
    //     this.elements = response.data.customers.active;
    //     // this.mdbTable.setDataSource(this.elements);
    //     // this.previous = this.mdbTable.getDataSource();
    //     // this.cdRef.detectChanges();
    //     this.countries = response.data.countries;
    //     this.scountries = response.data.countries;
    //     this.countryvalues = Object.values(response.data.countries);
    //     this.scountryvalues = Object.values(response.data.countries);
    //     this.ngxService.stop();
    //   });
  }
  onSend(e) {
    if(this.customer.email == this.customer.secondary_email || this.customer.email == this.customer.additional_email || this.customer.secondary_email == this.customer.additional_email && this.customer.secondary_email){
      Swal.fire('',"Email already exist!", 'warning');
      return false;
    }
    this.ngxService.start();
    let request = {
      "id":this.customer.id,
      "first_name": this.customer.firstname,
      "last_name": this.customer.lastname,
      "company_name": this.customer.company_name,
      "email": this.customer.email,
      'secondary_email':this.customer.secondary_email,
       'additional_email' : this.customer.additional_email,
      "phone": this.customer.mobile,
      "billing_address": this.customer.baddress,
      "billing_city": this.customer.bcity,
      "billing_state": this.customer.bstate,
      "billing_zipcode": this.customer.bzipcode,
      "billing_country": this.customer.bcountry,
      "shipping_address": this.customer.saddress,
      "shipping_city": this.customer.scity,
      "shipping_state": this.customer.sstate,
      "shipping_zipcode": this.customer.szipcode,
      "shipping_country": this.customer.scountry,
      "created_by": this.customer.created_by,
       image: this.profilePicture,
       is_customer_active: this.customer.customeractive == true || this.customer.customeractive == null ? "yes" : "no",
       same_as_address: this.customer.sameaddress == true ? "yes" : "no",
    }
     // console.log("this.customer.useractive=>>>>>>>",this.customer.customeractive)
    // console.log(request);return;
    let header = new HttpHeaders();
    header.set('Access-Control-Allow-Origin', '*');
    this.httpClient
      .post(this.http.BASE_URI + 'addUpdateCustomer', request)
      .subscribe((response: any) => {
        // console.log("Response", response)
        var status = response.success;
        if(status == false){
          //  console.log(response.data.email)
             var errorMessage = response.data
             var errorMessagevalues = Object.values(errorMessage);
            // console.log(errorMessagevalues)
            var error = '';
             errorMessagevalues.forEach((e)=>{
               error += e;
             })
              Swal.fire('',error, 'warning');
              this.ngxService.stop();
              return false;
            }
        this.check = !this.check;
        this.getdata();
        this.ngxService.stop();
        if (e == 'insert') {
          Swal.fire('', 'Customer created successfully and mail has been sent to your registered email', 'success');
        } else {
          Swal.fire('', 'Customer updated successfully', 'success');
        }
        this.onReset();
        this.customer.id=""
        this.cancel();
      });
  }
  keyPressNumbers(event) {
    var charCode = (event.which) ? event.which : event.keyCode;
    // Only Numbers 0-9
    if ((charCode < 48 || charCode > 57)) {
      event.preventDefault();
      return false;
    } else {
      return true;
    }
  }
  sort(property) {
    this.isDesc = !this.isDesc; //change the direction    
    this.column = property;
    let direction = this.isDesc ? 1 : -1;

    this.elements.sort(function (a, b) {
      if (a[property].toLowerCase() < b[property].toLowerCase()) {
        return -1 * direction;
      }
      else if (a[property].toLowerCase() > b[property].toLowerCase()) {
        return 1 * direction;
      }
      else {
        return 0;
      }
    });
  };
  fileChangeEvent(fileInput: any) {
    if (fileInput.target.files && fileInput.target.files[0]) {
      // Size Filter Bytes
      const max_size = 20971520;
      const allowed_types = ["image/png", "image/jpeg"];
      const max_height = 15200;
      const max_width = 25600;

      if (fileInput.target.files[0].size > max_size) {
        Swal.fire(
          "Oops...",
          "Maximum size allowed is " + max_size / 1000 + "Mb",
          "error"
        );
        return false;
      }

      if (!_.includes(allowed_types, fileInput.target.files[0].type)) {
        Swal.fire("Oops...", "Only Images are allowed ( JPG | PNG )", "error");
        return false;
      }
      const reader = new FileReader();
      reader.onload = (e: any) => {
        const image = new Image();
        image.src = e.target.result;
        image.onload = (rs) => {
          const img_height = rs.currentTarget["height"];
          const img_width = rs.currentTarget["width"];

          // console.log(img_height, img_width);

          if (img_height > max_height && img_width > max_width) {
            Swal.fire(
              "Oops...",
              "Maximum dimentions allowed " +
                max_height +
                "*" +
                max_width +
                "px",
              "error"
            );
            return false;
          } else {
            const imgBase64Path = e.target.result;
            this.profilePicture = e.target.result;
            this.isProfiled = true;
            this.isIntial = false;
            this.isCustomerAdd = false;
            // localStorage.setItem("ProfileImg", imgBase64Path);
            // this.ngxService.start();
            // let request = {
            //   image: imgBase64Path,
            // };
            // this.httpClient
            //   .post(this.http.BASE_URI + "uploadProfile/"+localStorage.getItem('Id'), request)
            //   .subscribe((response: any) => {
            //     // console.log("Response", response);
            //     this.ngOnInit();
            //     this.ngxService.stop();

            //   });
            // this.previewImagePath = imgBase64Path;
          }

          // this.imageSource = this.sanitizer.bypassSecurityTrustResourceUrl(this.cardImageBase64);
        };
      };
      reader.readAsDataURL(fileInput.target.files[0]);
    }
  }
  loadPage(e){
    this.numberOfRecords = (e - 1) * this.pageSize + this.pageSize > this.elements.length ? this.elements.length :  (e - 1) * this.pageSize + this.pageSize;
  }
  //copy shipping address
  copyBillingaddress(e){
   
    if(e.target.checked){
    this.customer.saddress = this.customer.baddress;
    this.customer.scountry = this.customer.bcountry ;
    this.customer.sstate = this.customer.bstate;
    this.customer.scity = this.customer.bcity ;
    this.customer.szipcode = this.customer.bzipcode ;
    }else{
    this.customer.saddress ='';
    this.customer.scountry ='';
    this.customer.sstate =''
    this.customer.scity ='';
    this.customer.szipcode ='';
    }
    }
    
  //make customer active and inactive
  customerActiveInactive(id,value){
    let request ={
      id,value
    };
    this.httpClient.post(this.http.BASE_URI + 'activeInactiveCustomer', request)
    .subscribe((response: any) => {
      var status = response.success;
    //  console.log(status)
      if(status){

      }else{

      }
      this.check = !this.check;
      this.getdata();
    });
  }
}
