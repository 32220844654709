import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { environment } from "../../../environments/environment";
@Injectable({
    providedIn: "root"
  })
  export class HttpProvider {
    public BASE_URI: string = environment.baseUri;
    public LOCAL_URI: string = environment.localUri;
    public isStatusLoading: boolean = true;
    public callback: any;
    public errorCallback: any;
    public loader: any;
    public PASSWORD_HASH: any = "@!^`90()AQWER--secretkeyspareit123";
  
    constructor(
      private httpClient: HttpClient,
    //   private googleAnalyticsService: GoogleAnalyticsService
    ) {}
  
    /**
     * Post http service using HttpClient.
     * Subscribe the current observer as observable.
     * Evaluate bind callback function.
     * Handle error callback if any.
     * param {string} url
     * param body
     */
  
    public postService(body?: any): any {
      // console.log(this.BASE_URI, body);
      // this.httpClient.post(this.BASE_URI, body).subscribe((response: any) => {
      //     const resValue: any = response;
      //     this.populateResponse(resValue);
      // }, (error: any) => {
      //     return this.errorCallback(error);
      // });
    }
  
    public post(url: string, body?: any): any {
      // console.log(url, body);
      this.httpClient.post(url, body).subscribe(
        (response: any) => {
          const resValue: any = response;
          this.populateResponse(resValue);
        },
        (error: any) => {
          return this.errorCallback(error);
        }
      );
    }
  
    /**
     * Get http service using HttpClient.
     * Subscribe the current observer as observable.
     * Evaluate bind callback function.
     * Handle errorCallback if any.
     * param {string} url
     */
    public get(url: string): any {
      this.httpClient.get(url).subscribe(
        (response: any) => {
          const resValue: any = response;
          this.populateResponse(resValue);
        },
        () => {
          // if (this.errorCallback != null) {
          //   // return this.errorCallback(error);
          // }
        }
      );
    }
  
    /**
     * General invocation function as globally.
     * Assign serviceConfigs, request, callback & errorCallback.
     * Generate base_uri & form the http urls with http methods.
     * param config
     * param request
     * param callback
     * param errorCallback
     * returns {Observable<any>}
     */
    
  
    public populateResponse(resValue: any): any {
      if (resValue.Status === "Error") {
        let responseError: any;
        // Handle xml error response (ResponseErrors -> ResponseError)
        if (resValue.ResponseError) {
          responseError = resValue.ResponseError;
        } else if (resValue.ResponseErrors) {
          responseError = [resValue.ResponseErrors.ResponseError];
        }
  
        if (
          this.errorCallback !== null &&
          typeof this.errorCallback === "function"
        ) {
          return this.errorCallback(resValue);
        }
      } else {
        if (this.callback !== null && typeof this.callback === "function") {
          return this.callback(resValue);
        }
      }
    }
  
    public saveGAEvent(gaCategory?: string, gaAction?: string, gaLabel?: string) {
      // if (environment.GAEventTrack) {
      //    // this.googleAnalyticsService.emitEvent(gaCategory, gaAction, gaLabel);
      // }
    }
  
    /**
     * randomNumberGen
     * @param randomNumber
     */
    private static randomNumberGen(url): string {
      let randomNumber = Math.floor(Math.random() * (999999 - 100000)) + 100000;
      if (url.match(/[=\?]/)) return "&random=" + randomNumber;
      return "?random=" + randomNumber;
    }
  
    /**
     * Append environment url
     * @param url
     */
    /* private static getRequestOptionArgs(options?: RequestOptionsArgs): RequestOptionsArgs {
          if (options == null || options == undefined) {
              options = new RequestOptions();
          }
          if (options.headers == null) {
              options.headers = new Headers();
          }
          options.headers.append('Content-Type', 'application/json');
          options.headers.append('Authorization', LocalStorageHelper.getLocalStorageItemByKey(LocalStorageConst.accessToken));
          return options;
      }*/
  }
  